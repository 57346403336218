import React, { useState } from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import ButtonBlue from "../../shared/general/ButtonBlue";

import utils from "../../../../utils";
import { addLeaveEmployees, getLeaveAvailable } from "../../../../actions/leave";
import { getLeaveRequestAllYear } from "../../../../actions/employee";

const StyledRoot = styled("div")({
  width: 350,
  padding: 24,
  "& .wrap-footer-btn": {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 16,
  },
});

const StyledRow = styled("div")({
  marginBottom: 16,
});

const DialogConfirmLeave = (props) => {
  const {
    handleClose,
    data,
    handleChangeAlertType,
    handleOpenAlert,
    openConfirm,
    leaveRoundConfig,
  } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const today = dayjs().toDate();
  dayjs.extend(isBetween);
  dayjs.extend(isSameOrAfter);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  // const { result: leaveAvailable } = useSelector((state) => state.leaveType);
  const { result: leaveEmployeesList } = useSelector(
    (state) => state.leaveEmployees
  );
  const { result: leaveAvailable } = useSelector((state) => state.leaveAvailable);
  const { result: leavePatternList } = useSelector(
    (state) => state.leavePattern
  );

  const [disabledBtn, setDisabledBtn] = useState(false);

  const displayNumberLeave = () => {
    const date1 = dayjs(data.endDate);
    const diff = date1.diff(data.startDate, "minute");
    if (data.leaveDayType === "fullday") {
      return diff / -24 + 1;
    } else {
      return diff / 60;
    }
  };

  const handleSubmit = async () => {
    setDisabledBtn(true);
    const found = leaveAvailable && leaveAvailable.find(
      (item) => item.idLeaveType === data.idLeaveType
    );
    const formData = {
      idLeaveType: data.idLeaveType,
      description: data.description,
      start: dayjs(data.startDate).format("YYYY-MM-DD HH:mm:ss"),
      end: dayjs(data.endDate).format("YYYY-MM-DD HH:mm:ss"),
      idEmp: userProfile.idEmp,
      used: parseFloat(displayNumberLeaveDays()).toFixed(2),
      quota: found.leaveValue,
      balance: found.leaveValue
        ? parseFloat(calculatorBalance()).toFixed(2)
        : 0,
      remaining: found.leaveValue
        ? parseFloat(calculatorBalance() - displayNumberLeaveDays()).toFixed(2)
        : 0,
      idManagerEmployee: userProfile.managerLV1Id,
      managerLV1Email: userProfile.managerLV1Email,
      isApprove: null,
      isFullDay: found.leaveValue
        ? parseFloat(displayNumberLeaveDays()).toFixed(2) >= 1
          ? 1
          : 0
        : 1,
      isActive: 1,
      leaveName: data && leaveAvailable && renderLeaveType(),
    };

    const postData = new FormData();

    Object.keys(formData).map((k) => {
      postData.append(k, JSON.stringify(formData[k]));
    });

    if (data.file) {
      postData.append("file", data.file);
    }
    const result = await dispatch(addLeaveEmployees(postData));
    if (result) {
      console.log("result: ", result);
      if (result.status !== 200) {
        handleChangeAlertType(result.data.message || "error");
        handleOpenAlert(true);
      } else {
        handleChangeAlertType("success");
        handleOpenAlert(true);
        dispatch(getLeaveRequestAllYear({filter: dayjs(leaveRoundConfig.date).format("YYYY")}));
        dispatch(getLeaveAvailable({date: dayjs(leaveRoundConfig.date).format("YYYY-MM-DD")}));
        openConfirm(false);
        handleClose();
      }
    } else {
      openConfirm(false);
      handleOpenAlert(true);
      handleChangeAlertType("error");
    }
  };

  const renderLeaveType = () => {
    const found = leaveAvailable && leaveAvailable.find(
      (item) => item.idLeaveType === data.idLeaveType
    );
    return found ? found.name : "-";
  };

  const calculatorBalance = () => {
    const found = leaveAvailable && leaveAvailable.find(
      (item) => item.idLeaveType === data.idLeaveType
    );
    if (leaveEmployeesList.length === 0) {
      return found ? found.leaveValue : 0;
    } else {
      const find = leaveEmployeesList.filter(
        (x) => x.idLeaveType === data.idLeaveType && x.isApprove !== 0
      );
      return find && found
        ? find.length > 0
          ? find[find.length - 1].remaining
          : found.leaveValue
        : 0;
    }
  };

  const displayNumberLeaveDays = () => {
    if (
      data.idLeaveType === 0 ||
      !leavePatternList ||
      leavePatternList.length <= 0 ||
      !leaveAvailable ||
      leaveAvailable.length <= 0
    ) {
      return 0;
    } else {
      if (data.leaveDayType === "fullday") {
        if (dayjs(data.startDate).isSame(data.endDate, "date")) {
          return 1;
        } else {
          const date1 = dayjs(data.endDate);
          const diff = date1.diff(data.startDate, "day");
          let sum = diff + 1;
          let leaveType = leaveAvailable && leaveAvailable.find((leave) => leave.idLeaveType === data.idLeaveType);

          if (leaveType && leaveType.isWeekend) {
            let patternEmployee = leavePatternList[1].pattern;
            if (patternEmployee.idWorkingType === 2) {
              for (let i = 0; i < diff + 1; i++) {
                let indexDay = parseInt(
                  dayjs(data.startDate).add(i, "day").format("d")
                );
                if (
                  (patternEmployee.workDay === 5 &&
                    (indexDay === 0 || indexDay === 6)) ||
                  (patternEmployee.workDay === 6 && indexDay === 0)
                ) {
                  sum -= 1;
                }
              }
            }
          }
          return sum;
        }
      } else {
        if (dayjs(data.startDate).isSame(data.endDate, "minute")) {
          return 1;
        } else {
          const date1 = dayjs(data.endDate);
          const diff = date1.diff(data.startDate, "minute");
          if (diff < 0) {
            return 0;
          } else {
            if (
              dayjs(data.endDate).isBetween(
                dayjs(data.endDate)
                  .set("hour", 12)
                  .set("minute", 0)
                  .set("second", 0),
                dayjs(data.endDate)
                  .set("hour", 12)
                  .set("minute", 59)
                  .set("second", 59),
                "minute",
                "(]"
              )
            ) {
              return (9 * 60) / 2 / 60 / 8;
            } else if (
              dayjs(data.endDate).isSameOrAfter(
                dayjs(data.endDate)
                  .set("hour", 12)
                  .set("minute", 59)
                  .set("second", 59),
                "minute"
              )
            ) {
              const findShiftType = leavePatternList && leavePatternList.find(item => item.date === dayjs(data.startDate).format("YYYY-MM-DD"));
              const checkedNightShift = findShiftType && findShiftType.pattern && findShiftType.pattern.period;
              if (checkedNightShift === 3) {
                return utils.numberWithCommas((diff / 60) / 8);
              } else {
                const pattern = findShiftType.pattern;
                if (pattern && pattern.breakTime === 0) {
                  let breakMin = pattern.breakTimeMin || 60;

                  const leaveStart = dayjs(data.startDate);
                  const leaveEnd = dayjs(data.endDate);
                  const breakStart = dayjs(leaveStart.format("YYYY-MM-DD") + ' ' + pattern.startBreak, 'YYYY-MM-DD HH:mm:ss');
                  const breakEnd = breakStart.add(breakMin, 'minute');

                  let totalLeaveDuration = leaveEnd.diff(leaveStart, 'minute');

                  if (leaveEnd.isBetween(breakStart, breakEnd, null, '[)')) {
                    const overlap = leaveEnd.diff(breakStart, 'minute');
                    totalLeaveDuration -= overlap;
                  } else if (leaveStart.isBetween(breakStart, breakEnd, null, '[)')) {
                    const overlap = breakEnd.diff(leaveStart, 'minute');
                    totalLeaveDuration -= overlap;
                  } else if (leaveStart.isBefore(breakStart) && leaveEnd.isAfter(breakEnd)) {
                    totalLeaveDuration -= breakMin;
                  };

                  if (totalLeaveDuration < 0) totalLeaveDuration = 0;
                  return utils.numberWithCommas((totalLeaveDuration / 60) / 8);
                } else {
                  return utils.numberWithCommas((diff / (60 - 1)) / 8);
                };
              };
            } else {
              return diff / 60 / 8;
            }
          }
        }
      }
    }
  };

  const displayNumberLeaveHours = () => {
    if (data.idLeaveType === 0) {
      return 0;
    } else {
      if (data.leaveDayType === "fullday") {
        if (dayjs(data.startDate).isSame(data.endDate, "date")) {
          return 8;
        } else {
          const date1 = dayjs(data.endDate);
          const diff = date1.diff(data.startDate, "day");
          return (diff + 1) * 8;
        }
      } else {
        if (dayjs(data.startDate).isSame(data.endDate, "minute")) {
          return 8;
        } else {
          const date1 = dayjs(data.endDate);
          const diff = date1.diff(data.startDate, "minute");
          if (diff < 0) {
            return 0;
          } else {
            if (
              dayjs(data.endDate).isBetween(
                dayjs(data.endDate)
                  .set("hour", 12)
                  .set("minute", 0)
                  .set("second", 0),
                dayjs(data.endDate)
                  .set("hour", 12)
                  .set("minute", 59)
                  .set("second", 59),
                "minute",
                "(]"
              )
            ) {
              return (9 * 60) / 2 / 60;
            } else if (
              dayjs(data.endDate).isSameOrAfter(
                dayjs(data.endDate)
                  .set("hour", 12)
                  .set("minute", 59)
                  .set("second", 59),
                "minute"
              )
            ) {
              return diff / 60 - 1;
            } else {
              return diff / 60;
            }
          }
        }
      }
    }
  };

  const displayRemaining = () => {
    const found = leaveAvailable && leaveAvailable.find((item) => item.idLeaveType === data.idLeaveType);

    if (leaveAvailable && leaveAvailable.length === 0) {
      return found
        ? `${utils.numberWithCommas(
          (found.leaveRemain ? found.leaveRemain : 0) + (found.carryRemain ? found.carryRemain : 0)
        )} วัน`
        : `${utils.numberWithCommas(0)} วัน`;
    } else {
      return found
        ? `${utils.numberWithCommas(
          (found.leaveRemain ? found.leaveRemain : 0) + (found.carryRemain ? found.carryRemain : 0)
        )} วัน`
        : `${utils.numberWithCommas(0)} วัน`;
    }
  };

  return (
    <StyledRoot>
      <Typography variant="h6">{t("ConfirmLeave")}</Typography>
      <div>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            {t("LeaveType")}
          </Typography>
          <Typography variant="h6">
            {data && leaveAvailable && renderLeaveType()}
          </Typography>
        </StyledRow>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            {t("StartDate")}
          </Typography>
          <Typography variant="h6">
            {data.leaveDayType === "fullday"
              ? dayjs(data.startDate).format("DD MMMM YYYY")
              : dayjs(data.startDate).format("DD MMMM YYYY HH:mm")}
          </Typography>
        </StyledRow>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            {t("EndDate")}
          </Typography>
          <Typography variant="h6">
            {data.leaveDayType === "fullday"
              ? dayjs(data.endDate).format("DD MMMM YYYY")
              : dayjs(data.endDate).format("DD MMMM YYYY HH:mm")}
          </Typography>
        </StyledRow>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            {t("NumberLeaveDays")}
          </Typography>
          <Typography variant="h6">
            {displayNumberLeaveDays()} วัน
          </Typography>
          {/* <Typography variant="h6">
            {utils.numberWithCommas(displayNumberLeaveDays())} วัน (
            {utils.numberWithCommas(displayNumberLeaveHours())}
            ชั่วโมง)
          </Typography> */}
        </StyledRow>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            {t("RemainingLeaveRights")}
          </Typography>
          <Typography variant="h6">
            {leaveAvailable && displayRemaining()}
          </Typography>
        </StyledRow>
        <StyledRow>
          <Typography variant="body2" color="text.secondary">
            {t("Reason")}
          </Typography>
          {data.description ? (
            <Typography variant="h6">{data.description}</Typography>
          ) : (
            <Typography
              variant="h6"
              color="text.secondary"
              style={{ fontStyle: "oblique" }}
            >
              {t("NotSpecified")}
            </Typography>
          )}
        </StyledRow>
      </div>
      <div className="wrap-footer-btn">
        <ButtonBlue
          size=""
          variant={"text"}
          color="secondary"
          onClick={handleClose}
        >
          {t("Cancel")}
        </ButtonBlue>
        <ButtonBlue
          disabled={disabledBtn}
          onClick={handleSubmit}
          autoFocus
          variant={"contained"}
        >
          {t("ConfirmLeave")}
        </ButtonBlue>
      </div>
    </StyledRoot>
  );
};

export default DialogConfirmLeave;
