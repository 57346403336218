import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import {
  CardContent,
  Container,
  Divider,
  Grid,
  IconButton,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";

import format from "date-fns/format";
import getDay from "date-fns/getDay";
import th from "date-fns/locale/th";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { useDropzone } from "react-dropzone";

import AlertResponse from "../../shared/general/AlertResponse";
import ButtonBlue from "../../shared/general/ButtonBlue";
import CardStyled from "../../shared/general/Card";
import DialogConfirmWithdraw from "../../shared/general/DialogConfirmWithdraw";

import CardHistoryLeave from "./CardHistoryLeave";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CircleIcon from "@mui/icons-material/Circle";

import { getLeaveAvailable, getLeaveSetting, withdrawLeaveEmployee } from "../../../../actions/leave";
import { getLeaveQuota, getLeaveRequestAllYear } from "../../../../actions/employee";

import { allHoliday } from "../../../../actions/holiday";

import DialogNewLeave from "./dialogNewLeave";

import utils from "../../../../utils";
import leaveService from "../../../../services/leave.service";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

const StyledRoot = styled("div")({
  minWidth: 350,
  width: "100%",
  backgroundColor: "#FFFFFF !important",
  "& .wrap-btn-add": {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 24,
  },
  "& .quota": {
    padding: 24,
  },
});

const StyledCardDark = styled("div")({
  padding: 8,
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardTitle": {
    fontSize: 22,
  },
});

const StyledCalendar = styled(Calendar)({
  height: "350px !important",
  "& .rbc-date-cell": {
    paddingRight: 0,
    textAlign: "center",
  },
  "& .rbc-month-row": {
    justifyContent: "center",
  },
  "& .rbc-event-content": {
    display: "none !important",
  },
  "& .rbc-row-segment": {
    //]]display: "none !important",
  },
  "& .rbc-today": {
    borderRadius: 4,
  },
});

const StyledToolbarCalendar = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
  "& .todayBtn": {
    borderColor: "#007afe",
    color: "#ffffff",
    backgroundColor: "#007afe",
  },
  "& .prevNextBtn": {
    marginRight: 4,
    marginLeft: 4,
  },
  "& .toolbarCalendarLabel": {
    fontSize: 24,
  },
});

const StyledWrapHistory = styled("div")({
  display: "flex",
  flexWrap: "wrap",
});

const StyledDivider = styled(Divider)({
  marginTop: 8,
  marginBottom: 8,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
  ["@media only screen and (min-width: 600px)"]: {
    display: "none",
  },
});

const StyledEmpty = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 350,
});

const StyledWrapQuota = styled("div")({
  display: "flex",
  alignItems: "center",
  "& .right": {
    marginLeft: 16,
    "& .leave-name": {
      lineHeight: 1.2,
    },
  },
});

const locales = {
  "th-TH": th,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

function QuotaProgress(props) {
  const { data, employeeLeave } = props;
  const { t, i18n } = useTranslation();

  const leaveUsed = data && data.leaveUsed || 0;

  const renderUseLeave = () => {
    if (!data) {
      return 0;
    } else {
      return data.carryValue - leaveUsed >= 0 ? 0 : leaveUsed - data.carryValue;
    }
  };

  const renderCarry = () => {
    if (!data) {
      return data.carryValue ? data.carryValue : 0;
    } else {
      return data.carryValue - leaveUsed > 0 ? data.carryValue - leaveUsed : 0;
    }
  };

  return (
    <Grid item xs={12} sm={6}>
      <StyledWrapQuota>
        <Box style={{ position: "relative", display: "inline-flex" }}>
          <CircularProgress
            variant="determinate"
            style={{
              color: "#eeeeee",
            }}
            size={50}
            thickness={4}
            {...props}
            value={100}
          />
          <CircularProgress
            variant="determinate"
            value={
              leaveUsed > 0
                ? (leaveUsed /
                  ((data.carryValue ? data.carryValue : 0) + data.leaveValue)) *
                100
                : 0
            }
            style={{
              color: "#007afe",
              animationDuration: "550ms",
              position: "absolute",
              left: 0,
            }}
            size={50}
            {...props}
          ></CircularProgress>
          <Box
            style={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h6"
              style={{ fontSize: 16 }}
              component="div"
              color="text.third"
            >
              {utils.numberWithCommas(leaveUsed)}
            </Typography>
          </Box>
        </Box>
        <div className="right">
          <Typography variant="h6" className="leave-name">
            {data.name}
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            style={{ fontWeight: 500 }}
          >
            {t("LeaveRights")}:{" "}
            {data.leaveValue
              ? utils.numberWithCommas(data.leaveValue)
              : `${t("Unlimite")}`}{" "}
            / {t("Remaining")}:{" "}
            {data.leaveValue
              ? `${utils.numberWithCommas(
                data.leaveValue - (data.carryValue ? renderUseLeave() : leaveUsed)
              )}`
              : `${t("Unlimite")}`}
          </Typography>
          {data.carryValue ? (
            <Typography
              variant="body1"
              color="text.secondary"
              style={{ fontWeight: 500 }}
            >
              {t("CumulativeRights")}:{" "}
              {utils.numberWithCommas(data.carryValue ? data.carryValue : 0)}{" "}
              / {t("Remaining")}: {`${utils.numberWithCommas(renderCarry())}`}
            </Typography>
          ) : null}
        </div>
      </StyledWrapQuota>
    </Grid>
  );
}

function Leavelist() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const today = dayjs().toDate();
  dayjs.extend(isBetween);
  dayjs.extend(isSameOrAfter);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: leaveTypeList } = useSelector((state) => state.leaveType);
  const { result: leaveEmployeesList } = useSelector((state) => state.leaveEmployees);
  const { result: holiday } = useSelector((state) => state.holiday);
  const { result: leaveSetting } = useSelector((state) => state.leaveSetting);
  const { result: leaveAvailable } = useSelector((state) => state.leaveAvailable);
  const [fileSelected, setFileSelected] = useState([]);
  const [activeDate, setActiveDate] = useState({
    date: today,
    direction: null,
  });
  const [requestLeave, setRequestLeave] = React.useState({
    idLeaveType: 0,
    startDate: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0
    ),
    endDate: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0
    ),
    description: "",
    leaveDayType: "fullday",
    startDatehour: 0,
    startDateminute: 0,
    endDatehour: 0,
    endDateminute: 0,
  });
  const [dataWithDraw, setDataWithDraw] = useState(null);
  const [openNewDialog, setOpenNewDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const [selectedLeaveRoundDate, setSelecetedLeaveroundDate] = useState("");
  const [selectMenuLeaveRound, setSelectMenuLeaveRound] = useState([]);
  const [leaveRoundConfig, setLeaveRoundConfig] = useState({
    date: "",
    start: "",
    end: "",
  });

  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    data: {
      selectedLeaveRoundDate: "",
    },
  });

  const onDrawerClose = () => {
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const onDrawerOpen = () => {
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: true,
      data: {
        selectedLeaveRoundDate: selectedLeaveRoundDate,
      },
    }));
  };

  const eventPropGetter = (event, start, end, isSelected) => {
    let newStyle = {
      color: "black",
      borderRadius: 4,
      border: "none",
    };
    if (event.resource) {
      return {
        className: event.resource.typeEvent + "Event",
        style: newStyle,
      };
    } else {
      return {
        className: "",
        style: newStyle,
      };
    }
  };

  const CustomToolbar = (toolbar) => {
    const goToBack = async () => {
      toolbar.onNavigate("PREV");
      setActiveDate({
        date: toolbar.date,
        direction: "PREV",
      });
    };

    const goToNext = async () => {
      toolbar.onNavigate("NEXT");
      setActiveDate({
        date: toolbar.date,
        direction: "NEXT",
      });
    };

    const goToCurrent = async () => {
      toolbar.onNavigate("TODAY");
      setActiveDate({
        date: toolbar.date,
        direction: "TODAY",
      });
    };

    const label = () => {
      const date = dayjs(toolbar.date);
      return (
        <span style={{ fontSize: 18, margin: 8 }}>
          <b>{date.format("MMMM")}</b>
          <span> {date.format("YYYY")}</span>
        </span>
      );
    };

    return (
      <StyledToolbarCalendar>
        <label className={"toolbarCalendarLabel"}>{label()}</label>
        <div>
          <IconButton
            aria-label="prev"
            size="small"
            className={`prevNextBtn`}
            onClick={goToBack}
          >
            <ChevronLeftIcon fontSize="small" />
          </IconButton>
          <ButtonBlue
            size={"small"}
            variant={"contained"}
            onClick={goToCurrent}
          >
            วันนี้
          </ButtonBlue>
          <IconButton
            aria-label="next"
            size="small"
            className={`prevNextBtn`}
            onClick={goToNext}
          >
            <ChevronRightIcon fontSize="small" />
          </IconButton>
        </div>
      </StyledToolbarCalendar>
    );
  };

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      fileSelected.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [fileSelected]
  );

  useEffect(() => {
    if (userProfile) {
      dispatch(allHoliday(userProfile.idCompany, dayjs(new Date()).format("YYYY")));
      // dispatch(getLeaveQuota());
      // dispatch(getLeaveRequestAllYear(dayjs(today).format("YYYY")));
      // dispatch(getLeaveAvailable({ date: dayjs(today).format("YYYY-MM-DD"), idEmp: userProfile.idEmp }))
      dispatch(getLeaveSetting());
      fetchLeaveRound();
    }
  }, [userProfile]);

  const handleCloseDialogConfirmLeave = () => {
    setOpenNewDialog(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const resetForm = () => {
    setRequestLeave({
      idLeaveType: 0,
      startDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        0,
        0,
        0
      ),
      endDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        0,
        0,
        0
      ),
      description: "",
      leaveDayType: "fullday",
    });
  };

  const RenderHolidayList = () => {
    const { t, i18n } = useTranslation();

    var dateCurrent = null;
    if (activeDate.direction === "NEXT") {
      dateCurrent = dayjs(activeDate.date).add(1, "month").format("YYYY-MM-DD");
    } else if (activeDate.direction === "PREV") {
      dateCurrent = dayjs(activeDate.date)
        .subtract(1, "month")
        .format("YYYY-MM-DD");
    } else {
      dateCurrent = dayjs(today).format("YYYY-MM-DD");
    }

    if (holiday) {
      var holidayMonth = holiday.filter(
        (value) =>
          dayjs(value.dateHoliday).get("month") ===
          dayjs(dateCurrent).get("month") &&
          dayjs(value.dateHoliday).get("year") ===
          dayjs(dateCurrent).get("year")
      );
      if (holidayMonth.length > 0) {
        return holidayMonth.map((value) => (
          <div style={{ marginBottom: 8 }}>
            <Typography variant="h6" style={{ fontSize: 18 }}>
              <CircleIcon
                style={{
                  fontSize: 12,
                  marginRight: 8,
                  color: "#fc4b6c",
                }}
              />
              {value.name}
            </Typography>
            <Typography
              color="text.secondary"
              style={{
                paddingLeft: 12,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {dayjs(value.dateHoliday).format("DD MMM YYYY")}
            </Typography>
          </div>
        ));
      } else {
        return (
          <StyledEmpty className="empty-holiday">
            <Typography color="text.secondary" align="center">
              ไม่มีวันหยุด
            </Typography>
          </StyledEmpty>
        );
      }
    }
  };

  const handleDeleteLeave = async () => {
    const formData = {
      idLeave: dataWithDraw.idLeave,
      isApprove: dataWithDraw.isApprove
    };

    const result = await dispatch(withdrawLeaveEmployee(null, formData));
    if (result && result.status === 200) {
      handleChangeAlertType("success");
      handleOpenAlert();
      setOpenConfirmDialog(false);
      dispatch(getLeaveRequestAllYear({filter: dayjs(selectedLeaveRoundDate).format("YYYY")}));
      dispatch(getLeaveAvailable({date: dayjs(selectedLeaveRoundDate).format("YYYY-MM-DD")}));
    } else {
      handleChangeAlertType("error");
      handleOpenAlert();
      setOpenConfirmDialog(false);
    }
  };

  const handleClickCloseDialog = () => {
    setOpenConfirmDialog(false);
    setDataWithDraw(null);
  };
  
  const handleChangeLeaveRound = (date) => {
    setSelecetedLeaveroundDate(date);
    dispatch(getLeaveAvailable({ date: date }));
  };

  const fetchLeaveRound = () => {
    leaveService
      .getLeaveRoundList()
      .then((res) => {
        const today = dayjs();
        const foundLeaveRound = res.data.find((item) =>
          dayjs(today).isSameOrAfter(dayjs(item.start), "day")
          && dayjs(today).isSameOrBefore(dayjs(item.end), "day")
        );
        setSelectMenuLeaveRound(res.data);
        if(foundLeaveRound){
          setSelecetedLeaveroundDate(foundLeaveRound.date);
          setLeaveRoundConfig((prev) => ({
            ...prev,
            ...foundLeaveRound,
          }));
        }
      })
      .catch((error) => {
        setSelectMenuLeaveRound([]);
        setSelecetedLeaveroundDate("");
        setLeaveRoundConfig((prev) => ({
          ...prev,
          date: "",
          start: "",
          end: "",
        }));
      });
  };

  useEffect(() => {
    if (selectedLeaveRoundDate) {
      dispatch(
        getLeaveRequestAllYear({filter: dayjs(selectedLeaveRoundDate).format("YYYY")})
      );
      dispatch(
        getLeaveAvailable({date: dayjs(selectedLeaveRoundDate).format("YYYY-MM-DD")})
      );
      setLeaveRoundConfig((prev) => ({
        ...prev,
        ...selectMenuLeaveRound.find(
          (item) => item.date === selectedLeaveRoundDate
        ),
      }));
    }
  }, [selectedLeaveRoundDate]);

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8, marginBottom: 16 }}>
          {t("LeaveWork")}
        </Typography>
        <Box
          marginBottom="16px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          sx={{
            "@media only screen and (max-width: 600px)": {
              flexDirection: "column",
              gap: "16px",
              alignItems: "flex-end",
            },
          }}
        >
          <Box
            maxWidth="min-content"
            sx={{
              "@media only screen and (max-width: 600px)": {
                maxWidth: "100%",
                width: "100%",
              },
            }}
          >
            {leaveSetting && (
              <TextFieldTheme
                select
                label={t("SelectYear")}
                value={selectedLeaveRoundDate}
                onChange={(event) => {
                  handleChangeLeaveRound(event.target.value);
                }}
              >
                {selectMenuLeaveRound.map((item) => (
                  <MenuItem value={item.date}>
                    {i18n.language === "th"
                      ? `${dayjs(item.start).format("D MMMM BBBB")} - ${dayjs(
                          item.end
                        ).format("D MMMM BBBB")}`
                      : `${dayjs(item.start)
                          .locale(i18n.language)
                          .format("D MMMM YYYY")} - ${dayjs(item.end)
                          .locale(i18n.language)
                          .format("D MMMM YYYY")}`}
                  </MenuItem>
                ))}
              </TextFieldTheme>
            )}
          </Box>
          {leaveRoundConfig.end && dayjs().isSameOrBefore(dayjs(leaveRoundConfig.end).add(2, 'month'), 'date') && <ButtonBlue
            startIcon={<AddRoundedIcon />}
            variant="contained"
            onClick={onDrawerOpen}
            // onClick={() => setOpenNewDialog(true)}
          >
            {`${t("CreateLeave")}`}
          </ButtonBlue>}
        </Box>
        <CardStyled style={{ marginBottom: 16 }}>
          <div className="quota">
            <Typography
              variant="h4"
              style={{ marginBottom: 16 }}
              className={`cardTitle`}
              gutterBottom
            >
              {t("AllLeave")}
            </Typography>
            <Grid container spacing={2}>
              {leaveAvailable &&
                leaveAvailable.map((item) => (
                  <QuotaProgress
                    data={item}
                    employeeLeave={leaveAvailable.find(
                      (x) => x.idLeaveType === item.idLeaveType
                    )}
                  />
                ))}
            </Grid>
          </div>
        </CardStyled>
        <CardStyled>
          <CardContent>
            <Typography
              variant="h4"
              style={{ marginBottom: 16 }}
              className={`cardTitle`}
              gutterBottom
            >
              {t("LeaveCalendar")}
            </Typography>
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={6}>
                <div>
                  {holiday && (
                    <StyledCalendar
                      className="calendar-leave"
                      localizer={localizer}
                      events={holiday}
                      views={["month"]}
                      startAccessor="start"
                      endAccessor="end"
                      popup={true}
                      defaultDate={new Date()}
                      onSelectEvent={(selected) => {
                        console.log(selected);
                      }}
                      eventPropGetter={eventPropGetter}
                      components={{
                        toolbar: CustomToolbar,
                      }}
                      formats={{
                        dateFormat: "d",
                        dayHeaderFormat: "d MMMM yyyy",
                        weekdayFormat: "E",
                      }}
                    />
                  )}
                </div>
              </Grid>
              <StyledDivider />
              <Grid item xs={12} sm={6}>
                <div>{RenderHolidayList()}</div>
              </Grid>
            </Grid>
          </CardContent>
        </CardStyled>
        <StyledCardDark style={{ marginTop: 16, minHeight: 200 }}>
          <Typography variant="h4" className={`cardTitle`} gutterBottom>
            {t("LeaveHistory")}
          </Typography>
          <StyledWrapHistory>
            {leaveEmployeesList && leaveEmployeesList.length > 0 ? (
              leaveEmployeesList.map((item) => (
                <div key={item.idLeave}>
                  <CardHistoryLeave
                    handleDeleteLeave={handleDeleteLeave}
                    data={item}
                    setDataWithDraw={setDataWithDraw}
                    openConfirmDialog={openConfirmDialog}
                    setOpenConfirmDialog={setOpenConfirmDialog}
                  />
                </div>
              ))
            ) : (
              <Typography color="text.secondary">
                {t("NoLeaveHistory")}
              </Typography>
            )}
          </StyledWrapHistory>
        </StyledCardDark>
      </Container>

      <DialogNewLeave
        drawerConfig={drawerConfig}
        // onClose={onDrawerClose}
        leaveRoundConfig={leaveRoundConfig}
        // open={openNewDialog}
        handleClose={onDrawerClose}
        handleOpenAlert={handleOpenAlert}
        handleChangeAlertType={handleChangeAlertType}
      />

      {openConfirmDialog &&
        <DialogConfirmWithdraw
          mode={"2"}
          open={openConfirmDialog}
          data={dataWithDraw}
          handleClose={handleClickCloseDialog}
          handleDeleteLeave={handleDeleteLeave}
        />
      }
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </StyledRoot>
  );
}

export default Leavelist;
