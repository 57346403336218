export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const USERS_FETCHING = "USERS_FETCHING";
export const USERS_FAILED = "USERS_FAILED";
export const USERS_SUCCESS = "USERS_SUCCESS";

export const VENDOR_USERS_FETCHING = "VENDOR_USERS_FETCHING";
export const VENDOR_USERS_FAILED = "VENDOR_USERS_FAILED";
export const VENDOR_USERS_SUCCESS = "VENDOR_USERS_SUCCESS";

export const USERPROFILE_FETCHING = "USERPROFILE_FETCHING";
export const USERPROFILE_FAILED = "USERPROFILE_FAILED";
export const USERPROFILE_SUCCESS = "USERPROFILE_SUCCESS";

export const COURSE_FETCHING = "COURSE_FETCHING";
export const COURSE_FAILED = "COURSE_FAILED";
export const COURSE_SUCCESS = "COURSE_SUCCESS";

export const DEPARTMENT_FETCHING = "DEPARTMENT_FETCHING";
export const DEPARTMENT_FAILED = "DEPARTMENT_FAILED";
export const DEPARTMENT_SUCCESS = "DEPARTMENT_SUCCESS";

export const COURSEITEM_FETCHING = "COURSEITEM_FETCHING";
export const COURSEITEM_FAILED = "COURSEITEM_FAILED";
export const COURSEITEM_SUCCESS = "COURSEITEM_SUCCESS";

export const EMPLOYEE_FETCHING = "EMPLOYEE_FETCHING";
export const EMPLOYEE_FAILED = "EMPLOYEE_FAILED";
export const EMPLOYEE_SUCCESS = "EMPLOYEE_SUCCESS";

export const EMPLOYEEPROFILE_FETCHING = "EMPLOYEEPROFILE_FETCHING";
export const EMPLOYEEPROFILE_FAILED = "EMPLOYEEPROFILE_FAILED";
export const EMPLOYEEPROFILE_SUCCESS = "EMPLOYEEPROFILE_SUCCESS";

export const EMPLOYEE_POSITION_FETCHING = "EMPLOYEE_POSITION_FETCHING";
export const EMPLOYEE_POSITION_FAILED = "EMPLOYEE_POSITION_FAILED";
export const EMPLOYEE_POSITION_SUCCESS = "EMPLOYEE_POSITION_SUCCESS";

export const EMPLOYEE_SALARY_FETCHING = "EMPLOYEE_SALARY_FETCHING";
export const EMPLOYEE_SALARY_FAILED = "EMPLOYEE_SALARY_FAILED";
export const EMPLOYEE_SALARY_SUCCESS = "EMPLOYEE_SALARY_SUCCESS";

export const EMPLOYEE_TYPE_FETCHING = "EMPLOYEE_TYPE_FETCHING";
export const EMPLOYEE_TYPE_FAILED = "EMPLOYEE_TYPE_FAILED";
export const EMPLOYEE_TYPE_SUCCESS = "EMPLOYEE_TYPE_SUCCESS";

export const EMPLOYEE_EMPLOYEE_TYPE_FETCHING =
  "EMPLOYEE_EMPLOYEE_TYPE_FETCHING";
export const EMPLOYEE_EMPLOYEE_TYPE_FAILED = "EMPLOYEE_EMPLOYEE_TYPE_FAILED";
export const EMPLOYEE_EMPLOYEE_TYPE_SUCCESS = "EMPLOYEE_EMPLOYEE_TYPE_SUCCESS";

export const EMPLOYEE_ADDITION_DEDUCTION_FETCHING =
  "EMPLOYEE_ADDITION_DEDUCTION_FETCHING";
export const EMPLOYEE_ADDITION_DEDUCTION_FAILED =
  "EMPLOYEE_ADDITION_DEDUCTION_FAILED";
export const EMPLOYEE_ADDITION_DEDUCTION_SUCCESS =
  "EMPLOYEE_ADDITION_DEDUCTION_SUCCESS";

export const COMPANYPROFILE_FETCHING = "COMPANYPROFILE_FETCHING";
export const COMPANYPROFILE_FAILED = "COMPANYPROFILE_FAILED";
export const COMPANYPROFILE_SUCCESS = "COMPANYPROFILE_SUCCESS";

export const COMPANY_ORGANIZATION_FETCHING = "COMPANY_ORGANIZATION_FETCHING";
export const COMPANY_ORGANIZATION_FAILED = "COMPANY_ORGANIZATION_FAILED";
export const COMPANY_ORGANIZATION_SUCCESS = "COMPANY_ORGANIZATION_SUCCESS";

export const ORGCHART_FETCHING = "ORGCHART_FETCHING";
export const ORGCHART_FAILED = "ORGCHART_FAILED";
export const ORGCHART_SUCCESS = "ORGCHART_SUCCESS";

export const PROJECT_FETCHING = "PROJECT_FETCHING";
export const PROJECT_FAILED = "PROJECT_FAILED";
export const PROJECT_SUCCESS = "PROJECT_SUCCESS";

export const PROJECTASSIGN_FETCHING = "PROJECTASSIGN_FETCHING";
export const PROJECTASSIGN_FAILED = "PROJECTASSIGN_FAILED";
export const PROJECTASSIGN_SUCCESS = "PROJECTASSIGN_SUCCESS";

export const SALARYSLIP_FETCHING = "SALARYSLIP_FETCHING";
export const SALARYSLIP_FAILED = "SALARYSLIP_FAILED";
export const SALARYSLIP_SUCCESS = "SALARYSLIP_SUCCESS";

export const SUMMARY_TIME_FETCHING = "SUMMARY_TIME_FETCHING";
export const SUMMARY_TIME_FAILED = "SUMMARY_TIME_FAILED";
export const SUMMARY_TIME_SUCCESS = "SUMMARY_TIME_SUCCESS";

export const SUMMARY_TIME_COMPANY_FETCHING = "SUMMARY_TIME_COMPANY_FETCHING";
export const SUMMARY_TIME_COMPANY_FAILED = "SUMMARY_TIME_COMPANY_FAILED";
export const SUMMARY_TIME_COMPANY_SUCCESS = "SUMMARY_TIME_COMPANY_SUCCESS";

export const LOCATION_FETCHING = "LOCATION_FETCHING";
export const LOCATION_FAILED = "LOCATION_FAILED";
export const LOCATION_SUCCESS = "LOCATION_SUCCESS";

export const DAYOFFAPPROVAL_FETCHING = "DAYOFFAPPROVAL_FETCHING";
export const DAYOFFAPPROVAL_FAILED = "DAYOFFAPPROVAL_FAILED";
export const DAYOFFAPPROVAL_SUCCESS = "DAYOFFAPPROVAL_SUCCESS";

export const PAYTYPES_FETCHING = "PAYTYPES_FETCHING";
export const PAYTYPES_FAILED = "PAYTYPES_FAILED";
export const PAYTYPES_SUCCESS = "PAYTYPES_SUCCESS";

export const PAYRUNS_FETCHING = "PAYRUNS_FETCHING";
export const PAYRUNS_FAILED = "PAYRUNS_FAILED";
export const PAYRUNS_SUCCESS = "PAYRUNS_SUCCESS";

export const PAYROLL_SETTING_FETCHING = "PAYROLL_SETTING_FETCHING";
export const PAYROLL_SETTING_FAILED = "PAYROLL_SETTING_FAILED";
export const PAYROLL_SETTING_SUCCESS = "PAYROLL_SETTING_SUCCESS";

export const PAYRUNS_DETAIL_FETCHING = "PAYRUNS_DETAIL_FETCHING";
export const PAYRUNS_DETAIL_FAILED = "PAYRUNS_DETAIL_FAILED";
export const PAYRUNS_DETAIL_SUCCESS = "PAYRUNS_DETAIL_SUCCESS";

export const PAYSLIP_FETCHING = "PAYSLIP_FETCHING";
export const PAYSLIP_SUCCESS = "PAYSLIP_SUCCESS";
export const PAYSLIP_FAILED = "PAYSLIP_FAILED";

export const PAYSLIP_DETAIL_FETCHING = "PAYSLIP_DETAIL_FETCHING";
export const PAYSLIP_DETAIL_SUCCESS = "PAYSLIP_DETAIL_SUCCESS";
export const PAYSLIP_DETAIL_FAILED = "PAYSLIP_DETAIL_FAILED";

export const PAYRUNS_SUMMARY_YEAR_FETCHING = "PAYRUNS_SUMMARY_YEAR_FETCHING";
export const PAYRUNS_SUMMARY_YEAR_SUCCESS = "PAYRUNS_SUMMARY_YEAR_SUCCESS";
export const PAYRUNS_SUMMARY_YEAR_FAILED = "PAYRUNS_SUMMARY_YEAR_FAILED";

export const PAYRUNS_DETAIL_EMPLOYEES_FETCHING =
  "PAYRUNS_DETAIL_EMPLOYEES_FETCHING";
export const PAYRUNS_DETAIL_EMPLOYEES_FAILED =
  "PAYRUNS_DETAIL_EMPLOYEES_FAILED";
export const PAYRUNS_DETAIL_EMPLOYEES_SUCCESS =
  "PAYRUNS_DETAIL_EMPLOYEES_SUCCESS";

export const LEAVEWORKS_DETAIL_FETCHING = "LEAVEWORKS_DETAIL_FETCHING";
export const LEAVEWORKS_DETAIL_FAILED = "LEAVEWORKS_DETAIL_FAILED";
export const LEAVEWORKS_DETAIL_SUCCESS = "LEAVEWORKS_DETAIL_SUCCESS";

export const JOBFAMILY_DETAIL_FETCHING = "JOBFAMILY_DETAIL_FETCHING";
export const JOBFAMILY_DETAIL_FAILED = "JOBFAMILY_DETAIL_FAILED";
export const JOBFAMILY_DETAIL_SUCCESS = "JOBFAMILY_DETAIL_SUCCESS";

export const INSTITUTE_DETAIL_FETCHING = "INSTITUTE_DETAIL_FETCHING";
export const INSTITUTE_DETAIL_FAILED = "INSTITUTE_DETAIL_FAILED";
export const INSTITUTE_DETAIL_SUCCESS = "INSTITUTE_DETAIL_SUCCESS";

export const LEAVEAPPROVES_DETAIL_FETCHING = "LEAVEWORKS_DETAIL_FETCHING";
export const LEAVEAPPROVES_DETAIL_FAILED = "LEAVEAPPROVES_DETAIL_FAILED";
export const LEAVEAPPROVES_DETAIL_SUCCESS = "LEAVEAPPROVES_DETAIL_SUCCESS";

export const WORKTIMEREQUEST_DETAIL_FETCHING =
  "WORKTIMEREQUEST_DETAIL_FETCHING";
export const WORKTIMEREQUEST_DETAIL_FAILED = "WORKTIMEREQUEST_DETAIL_FAILED";
export const WORKTIMEREQUEST_DETAIL_SUCCESS = "WORKTIMEREQUEST_DETAIL_SUCCESS";

export const LOGRECORD_DETAIL_FETCHING = "LOGRECORD_DETAIL_FETCHING";
export const LOGRECORD_DETAIL_FAILED = "LOGRECORD_DETAIL_FAILED";
export const LOGRECORD_DETAIL_SUCCESS = "LOGRECORD_DETAIL_SUCCESS";

export const GROUP_LOCATIONS_FETCHING = "GROUP_LOCATIONS_FETCHING";
export const GROUP_LOCATIONS_FAILED = "GROUP_LOCATIONS_FAILED";
export const GROUP_LOCATIONS_SUCCESS = "GROUP_LOCATIONS_SUCCESS";

export const LOCATIONS_FETCHING = "LOCATIONS_FETCHING";
export const LOCATIONS_FAILED = "LOCATIONS_FAILED";
export const LOCATIONS_SUCCESS = "LOCATIONS_SUCCESS";
export const LOCATIONS_CLEAR = "LOCATIONS_CLEAR";

export const ATTENDANCE_FETCHING = "ATTENDANCE_FETCHING";
export const ATTENDANCE_FAILED = "ATTENDANCE_FAILED";
export const ATTENDANCE_SUCCESS = "ATTENDANCE_SUCCESS";

export const TIMELINE_FETCHING = "TIMELINE_FETCHING";
export const TIMELINE_FAILED = "TIMELINE_FAILED";
export const TIMELINE_SUCCESS = "TIMELINE_SUCCESS";

export const ATTENDANCE_LIST_FETCHING = "ATTENDANCE_LIST_FETCHING";
export const ATTENDANCE_LIST_FAILED = "ATTENDANCE_LIST_FAILED";
export const ATTENDANCE_LIST_SUCCESS = "ATTENDANCE_LIST_SUCCESS";

export const ATTENDANCE_ONCE_FETCHING = "ATTENDANCE_ONCE_FETCHING";
export const ATTENDANCE_ONCE_FAILED = "ATTENDANCE_ONCE_FAILED";
export const ATTENDANCE_ONCE_SUCCESS = "ATTENDANCE_ONCE_SUCCESS";

export const USER_LEAVE_FETCHING = "USER_LEAVE_FETCHING";
export const USER_LEAVE_FAILED = "USER_LEAVE_FAILED";
export const USER_LEAVE_SUCCESS = "USER_LEAVE_SUCCESS";

export const LEAVE_TYPE_FETCHING = "LEAVE_TYPE_FETCHING";
export const LEAVE_TYPE_FAILED = "LEAVE_TYPE_FAILED";
export const LEAVE_TYPE_SUCCESS = "LEAVE_TYPE_SUCCESS";

export const HOLIDAY_FETCHING = "HOLIDAY_FETCHING";
export const HOLIDAY_FAILED = "HOLIDAY_FAILED";
export const HOLIDAY_SUCCESS = "HOLIDAY_SUCCESS";

export const SHIFT_PATTERN_FETCHING = "SHIFT_PATTERN_FETCHING";
export const SHIFT_PATTERN_FAILED = "SHIFT_PATTERN_FAILED";
export const SHIFT_PATTERN_SUCCESS = "SHIFT_PATTERN_SUCCESS";

export const SHIFT_FETCHING = "SHIFT_FETCHING";
export const SHIFT_FAILED = "SHIFT_FAILED";
export const SHIFT_SUCCESS = "SHIFT_SUCCESS";

export const SHIFT_GROUP_FETCHING = "SHIFT_GROUP_FETCHING";
export const SHIFT_GROUP_FAILED = "SHIFT_GROUP_FAILED";
export const SHIFT_GROUP_SUCCESS = "SHIFT_GROUP_SUCCESS";

export const EMPLOYEE_SHIFT_FETCHING = "EMPLOYEE_SHIFT_FETCHING";
export const EMPLOYEE_SHIFT_FAILED = "EMPLOYEE_SHIFT_FAILED";
export const EMPLOYEE_SHIFT_SUCCESS = "EMPLOYEE_SHIFT_SUCCESS";

export const EMPLOYEE_NOT_SHIFT_FETCHING = "EMPLOYEE_NOT_SHIFT_FETCHING";
export const EMPLOYEE_NOT_SHIFT_FAILED = "EMPLOYEE_NOT_SHIFT_FAILED";
export const EMPLOYEE_NOT_SHIFT_SUCCESS = "EMPLOYEE_NOT_SHIFT_SUCCESS";

export const EXPERIENCE_FETCHING = "EXPERIENCE_FETCHING";
export const EXPERIENCE_FAILED = "EXPERIENCE_FAILED";
export const EXPERIENCE_SUCCESS = "EXPERIENCE_SUCCESS";

export const EDUCATION_FETCHING = "EDUCATION_FETCHING";
export const EDUCATION_FAILED = "EDUCATION_FAILED";
export const EDUCATION_SUCCESS = "EDUCATION_SUCCESS";

export const POSITIONS_FETCHING = "POSITIONS_FETCHING";
export const POSITIONS_FAILED = "POSITIONS_FAILED";
export const POSITIONS_SUCCESS = "POSITIONS_SUCCESS";

export const COMPANY_BRANCH_FETCHING = "COMPANY_BRANCH_FETCHING";
export const COMPANY_BRANCH_FAILED = "COMPANY_BRANCH_FAILED";
export const COMPANY_BRANCH_SUCCESS = "COMPANY_BRANCH_SUCCESS";

export const BANK_FETCHING = "BANK_FETCHING";
export const BANK_FAILED = "BANK_FAILED";
export const BANK_SUCCESS = "BANK_SUCCESS";

export const VENDOR_PROFILE_FETCHING = "VENDOR_PROFILE_FETCHING";
export const VENDOR_PROFILE_FAILED = "VENDOR_PROFILE_FAILED";
export const VENDOR_PROFILE_SUCCESS = "VENDOR_PROFILE_SUCCESS";

export const COMPANYS_FETCHING = "COMPANYS_FETCHING";
export const COMPANYS_FAILED = "COMPANYS_FAILED";
export const COMPANYS_SUCCESS = "COMPANYS_SUCCESS";

export const EMPLOYEESSHIFT_FETCHING = "EMPLOYEESSHIFT_FETCHING";
export const EMPLOYEESSHIFT_FAILED = "EMPLOYEESSHIFT_FAILED";
export const EMPLOYEESSHIFT_SUCCESS = "EMPLOYEESSHIFT_SUCCESS";

export const EMPLOYEESSHIFTHISTORY_FETCHING = "EMPLOYEESSHIFTHISTORY_FETCHING";
export const EMPLOYEESSHIFTHISTORY_FAILED = "EMPLOYEESSHIFTHISTORY_FAILED";
export const EMPLOYEESSHIFTHISTORY_SUCCESS = "EMPLOYEESSHIFTHISTORY_SUCCESS";

export const ADDITIONS_FETCHING = "ADDITIONS_FETCHING";
export const ADDITIONS_FAILED = "ADDITIONS_FAILED";
export const ADDITIONS_SUCCESS = "ADDITIONS_SUCCESS";

export const BILLING_FETCHING = "BILLING_FETCHING";
export const BILLING_FAILED = "BILLING_FAILED";
export const BILLING_SUCCESS = "BILLING_SUCCESS";

export const BILLING_COMPANYS_FETCHING = "BILLING_COMPANYS_FETCHING";
export const BILLING_COMPANYS_FAILED = "BILLING_COMPANYS_FAILED";
export const BILLING_COMPANYS_SUCCESS = "BILLING_COMPANYS_SUCCESS";

export const BILLING_VENDORS_FETCHING = "BILLING_VENDORS_FETCHING";
export const BILLING_VENDORS_FAILED = "BILLING_VENDORS_FAILED";
export const BILLING_VENDORS_SUCCESS = "BILLING_VENDORS_SUCCESS";

export const BILLING_EMPLOYEES_FETCHING = "BILLING_EMPLOYEES_FETCHING";
export const BILLING_EMPLOYEES_FAILED = "BILLING_EMPLOYEES_FAILED";
export const BILLING_EMPLOYEES_SUCCESS = "BILLING_EMPLOYEES_SUCCESS";

export const BILLING_DETAIL_FETCHING = "BILLING_DETAIL_FETCHING";
export const BILLING_DETAIL_FAILED = "BILLING_DETAIL_FAILED";
export const BILLING_DETAIL_SUCCESS = "BILLING_DETAIL_SUCCESS";

export const COMPANY_CONTRACT_FETCHING = "COMPANY_CONTRACT_FETCHING";
export const COMPANY_CONTRACT_FAILED = "COMPANY_CONTRACT_FAILED";
export const COMPANY_CONTRACT_SUCCESS = "COMPANY_CONTRACT_SUCCESS";

export const VENDOR_CONTRACT_FETCHING = "VENDOR_CONTRACT_FETCHING";
export const VENDOR_CONTRACT_FAILED = "VENDOR_CONTRACT_FAILED";
export const VENDOR_CONTRACT_SUCCESS = "VENDOR_CONTRACT_SUCCESS";

export const EMP_DATA_FORM_FETCHING = "EMP_DATA_FORM_FETCHING";
export const EMP_DATA_FORM_FAILED = "EMP_DATA_FORM_FAILED";
export const EMP_DATA_FORM_SUCCESS = "EMP_DATA_FORM_SUCCESS";

export const ADDITIONSANDTRAININGS_FETCHING = "ADDITIONSANDTRAININGS_FETCHING";
export const ADDITIONSANDTRAININGS_FAILED = "ADDITIONSANDTRAININGS_FAILED";
export const ADDITIONSANDTRAININGS_SUCCESS = "ADDITIONSANDTRAININGS_SUCCESS";

export const DIVISIONS_FETCHING = "DIVISIONS_FETCHING";
export const DIVISIONS_FAILED = "DIVISIONS_FAILED";
export const DIVISIONS_SUCCESS = "DIVISIONS_SUCCESS";

export const SECTIONS_FETCHING = "SECTIONS_FETCHING";
export const SECTIONS_FAILED = "SECTIONS_FAILED";
export const SECTIONS_SUCCESS = "SECTIONS_SUCCESS";

export const TRAININGS_FETCHING = "TRAININGS_FETCHING";
export const TRAININGS_FAILED = "TRAININGS_FAILED";
export const TRAININGS_SUCCESS = "TRAININGS_SUCCESS";

export const WORKTYPES_FETCHING = "WORKTYPES_FETCHING";
export const WORKTYPES_FAILED = "WORKTYPES_FAILED";
export const WORKTYPES_SUCCESS = "WORKTYPES_SUCCESS";

export const EXPERTISES_FETCHING = "EXPERTISES_FETCHING";
export const EXPERTISES_FAILED = "EXPERTISES_FAILED";
export const EXPERTISES_SUCCESS = "EXPERTISES_SUCCESS";

export const EXPERTISES_VENDOR_FETCHING = "EXPERTISES_VENDOR_FETCHING";
export const EXPERTISES_VENDOR_FAILED = "EXPERTISES_VENDOR_FAILED";
export const EXPERTISES_VENDOR_SUCCESS = "EXPERTISES_VENDOR_SUCCESS";

export const SERVICES_FETCHING = "SERVICES_FETCHING";
export const SERVICES_FAILED = "SERVICES_FAILED";
export const SERVICES_SUCCESS = "SERVICES_SUCCESS";

export const JOBLEVELS_FETCHING = "JOBLEVELS_FETCHING";
export const JOBLEVELS_FAILED = "JOBLEVELS_FAILED";
export const JOBLEVELS_SUCCESS = "JOBLEVELS_SUCCESS";

export const PERSONNEL_LEVELS_FETCHING = "PERSONNEL_LEVELS_FETCHING";
export const PERSONNEL_LEVELS_FAILED = "PERSONNEL_LEVELS_FAILED";
export const PERSONNEL_LEVELS_SUCCESS = "PERSONNEL_LEVELS_SUCCESS";

export const MANAGER_FETCHING = "MANAGER_FETCHING";
export const MANAGER_FAILED = "MANAGER_FAILED";
export const MANAGER_SUCCESS = "MANAGER_SUCCESS";

export const MANAGER_ID_FETCHING = "MANAGER_ID_FETCHING";
export const MANAGER_ID_FAILED = "MANAGER_ID_FAILED";
export const MANAGER_ID_SUCCESS = "MANAGER_ID_SUCCESS";

export const MANAGER_SETTING_FETCHING = "MANAGER_SETTING_FETCHING";
export const MANAGER_SETTING_FAILED = "MANAGER_SETTING_FAILED";
export const MANAGER_SETTING_SUCCESS = "MANAGER_SETTING_SUCCESS";

export const LEVELADDITION_FETCHING = "LEVELADDITION_FETCHING";
export const LEVELADDITION_FAILED = "LEVELADDITION_FAILED";
export const LEVELADDITION_SUCCESS = "LEVELADDITION_SUCCESS";

export const ADDITIONS_COMPANY_FETCHING = "ADDITIONS_COMPANY_FETCHING";
export const ADDITIONS_COMPANY_FAILED = "ADDITIONS_COMPANY_FAILED";
export const ADDITIONS_COMPANY_SUCCESS = "ADDITIONS_COMPANY_SUCCESS";

export const ADDITIONS_VENDOR_FETCHING = "ADDITIONS_VENDOR_FETCHING";
export const ADDITIONS_VENDOR_FAILED = "ADDITIONS_VENDOR_FAILED";
export const ADDITIONS_VENDOR_SUCCESS = "ADDITIONS_VENDOR_SUCCESS";

export const BRANCHES_VENDOR_FETCHING = "BRANCHES_VENDOR_FETCHING";
export const BRANCHES_VENDOR_FAILED = "BRANCHES_VENDOR_FAILED";
export const BRANCHES_VENDOR_SUCCESS = "BRANCHES_VENDOR_SUCCESS";

export const BANK_DETAIL_FETCHING = "BANK_DETAIL_FETCHING";
export const BANK_DETAIL_FAILED = "BANK_DETAIL_FAILED";
export const BANK_DETAIL_SUCCESS = "BANK_DETAIL_SUCCESS";

export const VENDOR_PROVIDENT_FUND_FETCHING = "VENDOR_PROVIDENT_FUND_FETCHING";
export const VENDOR_PROVIDENT_FUND_FAILED = "VENDOR_PROVIDENT_FUND_FAILED";
export const VENDOR_PROVIDENT_FUND_SUCCESS = "VENDOR_PROVIDENT_FUND_SUCCESS";

export const VENDOR_PF_SCHEDULE_FETCHING = "VENDOR_PF_SCHEDULE_FETCHING";
export const VENDOR_PF_SCHEDULE_FAILED = "VENDOR_PF_SCHEDULE_FAILED";
export const VENDOR_PF_SCHEDULE_SUCCESS = "VENDOR_PF_SCHEDULE_SUCCESS";

export const USER_PF_SCHEDULE_FETCHING = "USER_PF_SCHEDULE_FETCHING";
export const USER_PF_SCHEDULE_FAILED = "USER_PF_SCHEDULE_FAILED";
export const USER_PF_SCHEDULE_SUCCESS = "USER_PF_SCHEDULE_SUCCESS";

export const USER_PROVIDENT_FUND_FETCHING = "USER_PROVIDENT_FUND_FETCHING";
export const USER_PROVIDENT_FUND_FAILED = "USER_PROVIDENT_FUND_FAILED";
export const USER_PROVIDENT_FUND_SUCCESS = "USER_PROVIDENT_FUND_SUCCESS";

export const LEAVEEMPLOYEES_FETCHING = "LEAVEEMPLOYEES_FETCHING";
export const LEAVEEMPLOYEES_FAILED = "LEAVEEMPLOYEES_FAILED";
export const LEAVEEMPLOYEES_SUCCESS = "LEAVEEMPLOYEES_SUCCESS";

export const LEAVEEMPLOYEES_WITHDRAW_FETCHING =
  "LEAVEEMPLOYEES_WITHDRAW_FETCHING";
export const LEAVEEMPLOYEES_WITHDRAW_FAILED = "LEAVEEMPLOYEES_WITHDRAW_FAILED";
export const LEAVEEMPLOYEES_WITHDRAW_SUCCESS =
  "LEAVEEMPLOYEES_WITHDRAW_SUCCESS";

export const LEAVE_PATTERN_FETCHING = "LEAVE_PATTERN_FETCHING";
export const LEAVE_PATTERN_FAILED = "LEAVE_PATTERN_FAILED";
export const LEAVE_PATTERN_SUCCESS = "LEAVE_PATTERN_SUCCESS";

export const REQUESTTIME_FETCHING = "REQUESTTIME_FETCHING";
export const REQUESTTIME_FAILED = "REQUESTTIME_FAILED";
export const REQUESTTIME_SUCCESS = "REQUESTTIME_SUCCESS";

export const REQUESTTIME_REASON_FETCHING = "REQUESTTIME_REASON_FETCHING";
export const REQUESTTIME_REASON_FAILED = "REQUESTTIME_REASON_FAILED";
export const REQUESTTIME_REASON_SUCCESS = "REQUESTTIME_REASON_SUCCESS";

export const REQUESTTIME_WITHDRAW_FETCHING = "REQUESTTIME_WITHDRAW_FETCHING";
export const REQUESTTIME_WITHDRAW_SUCCESS = "REQUESTTIME_WITHDRAW_SUCCESS";
export const REQUESTTIME_WITHDRAW_FAILED = "REQUESTTIME_WITHDRAW_FAILED";

export const DASHBOARD_OVERVIEW_FETCHING = "DASHBOARD_OVERVIEW_FETCHING";
export const DASHBOARD_OVERVIEW_FAILED = "DASHBOARD_OVERVIEW_FAILED";
export const DASHBOARD_OVERVIEW_SUCCESS = "DASHBOARD_OVERVIEW_SUCCESS";

export const DASHBOARD_OVERTIME_FETCHING = "DASHBOARD_OVERTIME_FETCHING";
export const DASHBOARD_OVERTIME_FAILED = "DASHBOARD_OVERTIME_FAILED";
export const DASHBOARD_OVERTIME_SUCCESS = "DASHBOARD_OVERTIME_SUCCESS";

export const DASHBOARD_COST_FETCHING = "DASHBOARD_COST_FETCHING";
export const DASHBOARD_COST_FAILED = "DASHBOARD_COST_FAILED";
export const DASHBOARD_COST_SUCCESS = "DASHBOARD_COST_SUCCESS";

export const DASHBOARD_WORKINGTIME_FETCHING = "DASHBOARD_WORKINGTIME_FETCHING";
export const DASHBOARD_WORKINGTIME_FAILED = "DASHBOARD_WORKINGTIME_FAILED";
export const DASHBOARD_WORKINGTIME_SUCCESS = "DASHBOARD_WORKINGTIME_SUCCESS";

export const USER_BENEFICIARYS_FETCHING = "USER_BENEFICIARYS_FETCHING";
export const USER_BENEFICIARYS_FAILED = "USER_BENEFICIARYS_FAILED";
export const USER_BENEFICIARYS_SUCCESS = "USER_BENEFICIARYS_SUCCESS";

export const TAX_DEDUCTION_FETCHING = "TAX_DEDUCTION_FETCHING";
export const TAX_DEDUCTION_FAILED = "TAX_DEDUCTION_FAILED";
export const TAX_DEDUCTION_SUCCESS = "TAX_DEDUCTION_SUCCESS";

export const TAX_DEDUCTION_SCHEDULE_FETCHING =
  "TAX_DEDUCTION_SCHEDULE_FETCHING";
export const TAX_DEDUCTION_SCHEDULE_FAILED = "TAX_DEDUCTION_SCHEDULE_FAILED";
export const TAX_DEDUCTION_SCHEDULE_SUCCESS = "TAX_DEDUCTION_SCHEDULE_SUCCESS";

export const AREA_FETCHING = "AREA_FETCHING";
export const AREA_FAILED = "AREA_FAILED";
export const AREA_SUCCESS = "AREA_SUCCESS";

export const AREA_DETAIL_FETCHING = "AREA_DETAIL_FETCHING";
export const AREA_DETAIL_FAILED = "AREA_DETAIL_FAILED";
export const AREA_DETAIL_SUCCESS = "AREA_DETAIL_SUCCESS";

export const ADMIN_LIST_FETCHING = "ADMIN_LIST_FETCHING";
export const ADMIN_LIST_SUCCESS = "ADMIN_LIST_SUCCESS";
export const ADMIN_LIST_FAILED = "ADMIN_LIST_FAILED";

export const PERFORMANCE_COMPANY_FETCHING = "PERFORMANCE_COMPANY_FETCHING";
export const PERFORMANCE_COMPANY_FAILED = "PERFORMANCE_COMPANY_FAILED";
export const PERFORMANCE_COMPANY_SUCCESS = "PERFORMANCE_COMPANY_SUCCESS";

export const PERFORMANCE_EMPLOYEE_FETCHING = "PERFORMANCE_EMPLOYEE_FETCHING";
export const PERFORMANCE_EMPLOYEE_FAILED = "PERFORMANCE_EMPLOYEE_FAILED";
export const PERFORMANCE_EMPLOYEE_SUCCESS = "PERFORMANCE_EMPLOYEE_SUCCESS";

export const GROUPLEVELS_COMPANY_FETCHING = "GROUPLEVELS_COMPANY_FETCHING";
export const GROUPLEVELS_COMPANY_FAILED = "GROUPLEVELS_COMPANY_FAILED";
export const GROUPLEVELS_COMPANY_SUCCESS = "GROUPLEVELS_COMPANY_SUCCESS";

export const MANAGER_GROUP_FETCHING = "MANAGER_GROUP_FETCHING";
export const MANAGER_GROUP_FAILED = "MANAGER_GROUP_FAILED";
export const MANAGER_GROUP_SUCCESS = "MANAGER_GROUP_SUCCESS";

export const ALL_CONTRACT_EMPLOYEES_FETCHING =
  "ALL_CONTRACT_EMPLOYEES_FETCHING";
export const ALL_CONTRACT_EMPLOYEES_FAILED = "ALL_CONTRACT_EMPLOYEES_FAILED";
export const ALL_CONTRACT_EMPLOYEES_SUCCESS = "ALL_CONTRACT_EMPLOYEES_SUCCESS";

export const ALL_EMPLOYEES_IN_CONTRACT_FETCHING =
  "ALL_EMPLOYEES_IN_CONTRACT_FETCHING";
export const ALL_EMPLOYEES_IN_CONTRACT_FAILED =
  "ALL_EMPLOYEES_IN_CONTRACT_FAILED";
export const ALL_EMPLOYEES_IN_CONTRACT_SUCCESS =
  "ALL_EMPLOYEES_IN_CONTRACT_SUCCESS";

export const CONTRACT_EMPLOYEES_DETAIL_FETCHING =
  "CONTRACT_EMPLOYEES_DETAIL_FETCHING";
export const CONTRACT_EMPLOYEES_DETAIL_FAILED =
  "CONTRACT_EMPLOYEES_DETAIL_FAILED";
export const CONTRACT_EMPLOYEES_DETAIL_SUCCESS =
  "CONTRACT_EMPLOYEES_DETAIL_SUCCESS";

export const RESERVE_EMPLOYEES_FETCHING = "RESERVE_EMPLOYEES_FETCHING";
export const RESERVE_EMPLOYEES_FAILED = "RESERVE_EMPLOYEES_FAILED";
export const RESERVE_EMPLOYEES_SUCCESS = "RESERVE_EMPLOYEES_SUCCESS";

export const CONTRACT_ALL_FETCHING = "CONTRACT_ALL_FETCHING";
export const CONTRACT_ALL_FAILED = "CONTRACT_ALL_FAILED";
export const CONTRACT_ALL_SUCCESS = "CONTRACT_ALL_SUCCESS";

export const RESERVE_EMPLOYEES_ALL_FETCHING = "RESERVE_EMPLOYEES_ALL_FETCHING";
export const RESERVE_EMPLOYEES_ALL_FAILED = "RESERVE_EMPLOYEES_ALL_FAILED";
export const RESERVE_EMPLOYEES_ALL_SUCCESS = "RESERVE_EMPLOYEES_ALL_SUCCESS";

export const OVERTIME_36HOURS_FETCHING = "OVERTIME_36HOURS_FETCHING";
export const OVERTIME_36HOURS_FAILED = "OVERTIME_36HOURS_FAILED";
export const OVERTIME_36HOURS_SUCCESS = "OVERTIME_36HOURS_SUCCESS";

export const OVERTIME_36HOURS_DETAIL_FETCHING =
  "OVERTIME_36HOURS_DETAIL_FETCHING";
export const OVERTIME_36HOURS_DETAIL_FAILED = "OVERTIME_36HOURS_DETAIL_FAILED";
export const OVERTIME_36HOURS_DETAIL_SUCCESS =
  "OVERTIME_36HOURS_DETAIL_SUCCESS";

export const TIMELINE_MONTH_FETCHING = "TIMELINE_MONTH_FETCHING";
export const TIMELINE_MONTH_FAILED = "TIMELINE_MONTH_FAILED";
export const TIMELINE_MONTH_SUCCESS = "TIMELINE_MONTH_SUCCESS";

export const EXPENSE_FETCHING = "EXPENSE_FETCHING";
export const EXPENSE_FAILED = "EXPENSE_FAILED";
export const EXPENSE_SUCCESS = "EXPENSE_SUCCESS";

export const EXPENSE_DETAIL_FETCHING = "EXPENSE_DETAIL_FETCHING";
export const EXPENSE_DETAIL_FAILED = "EXPENSE_DETAIL_FAILED";
export const EXPENSE_DETAIL_SUCCESS = "EXPENSE_DETAIL_SUCCESS";

export const ADMINS_FETCHING = "ADMINS_FETCHING";
export const ADMINS_FAILED = "ADMINS_FAILED";
export const ADMINS_SUCCESS = "ADMINS_SUCCESS";

export const EXPENSE_EXPORT_FETCHING = "EXPENSE_EXPORT_FETCHING";
export const EXPENSE_EXPORT_FAILED = "EXPENSE_EXPORT_FAILED";
export const EXPENSE_EXPORT_SUCCESS = "EXPENSE_EXPORT_SUCCESS";

export const EMPLOYEE_CONTRACT_DAILY_FAILED = "EMPLOYEE_CONTRACT_DAILY_FAILED";
export const EMPLOYEE_CONTRACT_DAILY_SUCCESS =
  "EMPLOYEE_CONTRACT_DAILY_SUCCESS";
export const EMPLOYEE_CONTRACT_DAILY_FETCHING =
  "EMPLOYEE_CONTRACT_DAILY_FETCHING";

export const LEAVE_FETCHING = "LEAVE_FETCHING";
export const LEAVE_FAILED = "LEAVE_FAILED";
export const LEAVE_SUCCESS = "LEAVE_SUCCESS";

export const ORGANIZATION_STRUCTURE_FETCHING =
  "ORGANIZATION_STRUCTURE_FETCHING";
export const ORGANIZATION_STRUCTURE_FAILED = "ORGANIZATION_STRUCTURE_FAILED";
export const ORGANIZATION_STRUCTURE_SUCCESS = "ORGANIZATION_STRUCTURE_SUCCESS";

export const ORGANIZATION_CHART_FETCHING = "ORGANIZATION_CHART_FETCHING";
export const ORGANIZATION_CHART_FAILED = "ORGANIZATION_CHART_FAILED";
export const ORGANIZATION_CHART_SUCCESS = "ORGANIZATION_CHART_SUCCESS";

export const JOBGROUP_FETCHING = "JOBGROUP_FETCHING";
export const JOBGROUP_FAILED = "JOBGROUP_FAILED";
export const JOBGROUP_SUCCESS = "JOBGROUP_SUCCESS";

export const LEVELS_FETCHING = "LEVELS_FETCHING";
export const LEVELS_FAILED = "LEVELS_FAILED";
export const LEVELS_SUCCESS = "LEVELS_SUCCESS";

export const NOTIFICATION_ALERT_OPEN = "NOTIFICATION_ALERT_OPEN";
export const NOTIFICATION_ALERT_CLOSE = "NOTIFICATION_ALERT_CLOSE";

export const PROBATION_QUESTION_FETCHING = "PROBATION_QUESTION_FETCHING";
export const PROBATION_QUESTION_FAILED = "PROBATION_QUESTION_FAILED";
export const PROBATION_QUESTION_SUCCESS = "PROBATION_QUESTION_SUCCESS";

export const RESULT_PROBATION_FETCHING = "RESULT_PROBATION_FETCHING";
export const RESULT_PROBATION_FAILED = "RESULT_PROBATION_FAILED";
export const RESULT_PROBATION_SUCCESS = "RESULT_PROBATION_SUCCESS";

export const PROBATION_EMPLOYEES_FETCHING = "PROBATION_EMPLOYEES_FETCHING";
export const PROBATION_EMPLOYEES_FAILED = "PROBATION_EMPLOYEES_FAILED";
export const PROBATION_EMPLOYEES_SUCCESS = "PROBATION_EMPLOYEES_SUCCESS";

export const EMPLOYEE_SHIFT_CHANGE_HISTORY_FETCHING =
  "EMPLOYEE_SHIFT_CHANGE_HISTORY_FETCHING";
export const EMPLOYEE_SHIFT_CHANGE_HISTORY_FAILED =
  "EMPLOYEE_SHIFT_CHANGE_HISTORY_FAILED";
export const EMPLOYEE_SHIFT_CHANGE_HISTORY_SUCCESS =
  "EMPLOYEE_SHIFT_CHANGE_HISTORY_SUCCESS";

export const EMPLOYEES_FETCHING = "EMPLOYEES_FETCHING";
export const EMPLOYEES_FAILED = "EMPLOYEES_FAILED";
export const EMPLOYEES_SUCCESS = "EMPLOYEES_SUCCESS";

export const EMPLOYEE_SHIFT_SCHEDULE_FETCHING =
  "EMPLOYEE_SHIFT_SCHEDULE_FETCHING";
export const EMPLOYEE_SHIFT_SCHEDULE_FAILED = "EMPLOYEE_SHIFT_SCHEDULE_FAILED";
export const EMPLOYEE_SHIFT_SCHEDULE_SUCCESS =
  "EMPLOYEE_SHIFT_SCHEDULE_SUCCESS";

export const LEAVE_SETTING_FETCHING = "LEAVE_SETTING_FETCHING";
export const LEAVE_SETTING_FAILED = "LEAVE_SETTING_FAILED";
export const LEAVE_SETTING_SUCCESS = "LEAVE_SETTING_SUCCESS";

export const LEAVE_AVAILABLE_FETCHING = "LEAVE_AVAILABLE_FETCHING";
export const LEAVE_AVAILABLE_FAILED = "LEAVE_AVAILABLE_FAILED";
export const LEAVE_AVAILABLE_SUCCESS = "LEAVE_AVAILABLE_SUCCESS";

export const TAX_SUMMARY_FETCHING = "TAX_SUMMARY_FETCHING";
export const TAX_SUMMARY_FAILED = "TAX_SUMMARY_FAILED";
export const TAX_SUMMARY_SUCCESS = "TAX_SUMMARY_SUCCESS";

export const EMPLOYEE_PERSONNEL_LEVEL_CHANGE_FETCHING = "EMPLOYEE_PERSONNEL_LEVEL_CHANGE_FETCHING";
export const EMPLOYEE_PERSONNEL_LEVEL_CHANGE_FAILED = "EMPLOYEE_PERSONNEL_LEVEL_CHANGE_FAILED";
export const EMPLOYEE_PERSONNEL_LEVEL_CHANGE_SUCCESS = "EMPLOYEE_PERSONNEL_LEVEL_CHANGE_SUCCESS";

export const BILLING_STATEMENT__FETCHING = "BILLING_STATEMENT__FETCHING";
export const BILLING_STATEMENT__FAILED = "BILLING_STATEMENT__FAILED ";
export const BILLING_STATEMENT__SUCCESS = "BILLING_STATEMENT__SUCCESS";

