import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { styled } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  getLeaveQuota,
  getLeaveRequestAllYear
} from "../../../../../../actions/employee";
import utils from '../../../../../../utils';
import CardHistoryLeave from "./CardHistoryLeave";

const StyledWrapBranch = styled("div")({
    marginBottom: 36,
    "& .wrap-branch-top": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 16,
      "& .MuiTypography-body2": {
        margin: 0,
      },
    },
    "& .branch-scroll": {
      height: 280,
      overflow: "auto",
    },
    "& .action": {
      textAlign: "right",
      "& .fal": {
        fontSize: 18,
      },
    },
    "& .address": {
      fontWeight: 600,
      fontSize: 18,
    },
    "& .contact": {
      "& .fal": {
        marginRight: 8,
      },
    },
});

const StyledHeadLabel = styled(Typography)({
    fontWeight: 600,
    fontSize: 14,
});

const StyledHistoryLeaves= styled("div")({
  padding: 8,
  marginTop: 16, 
  minHeight: 200,
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardTitle": {
    fontSize: 22,
  },
  "& .styledWrapHistory":{
    display: "flex",
    flexWrap: "wrap"
  }
});

const Leave = () => {
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { t } = useTranslation();
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: leaveTypeList } = useSelector((state) => state.leaveType);
  const { result: leaveEmployeesList } = useSelector(
    (state) => state.leaveEmployees
  );

  useEffect(() => {
    if (employeeProfile) {
      dispatch(getLeaveQuota(employeeProfile.idEmp, employeeProfile.idVendor));
      dispatch(getLeaveRequestAllYear({
        year: dayjs(today).format("YYYY"),
      },
      employeeProfile.idEmp));
    }
  }, [employeeProfile]);

  const renderUseLeave = (leave) => {
    if(leaveEmployeesList){
      const employeeLeave = leaveEmployeesList.filter(
        (x) => x.idLeaveType === leave.idLeaveType
      );

      const sum = employeeLeave
        .filter((x) => x.isActive && x.isApprove !== 0)
        .reduce((accumulator, object) => {
          return accumulator + object.used;
        }, 0);
      
      if (employeeLeave.length === 0) {
        return 0;
      } else {
        return sum;
      }
    }else{
      return 0;
    }
  };

  return(
      <div>
          <StyledWrapBranch>
              <div className="wrap-branch-top">
                <StyledHeadLabel variant="body2" gutterBottom>
                  {t("leaveAbsent")}
                </StyledHeadLabel>
              </div>
              {employeeProfile &&
                <TableContainer style={{ border:"1px solid rgba(0,0,0,0.1)", borderRadius:"16px" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ minWidth: 250 }}>
                          {t("LeaveType")}
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: 100 }}>
                          {t("LeaveRights")}/{t("CumulativeRights")} ({t("Days")})
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: 50 }}>
                          {t("Used")} ({t("Days")})
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: 120 }}>
                          {t("Remaining")} ({t("Days")})
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(leaveTypeList && leaveTypeList.length > 0) ?
                        leaveTypeList.map((leave) => (
                          <TableRow key={leave.idLeaveType}>
                            <TableCell
                              style={{ display: "flex", minHeight: "24.5px" }}
                            >
                              <Typography>{leave.name}</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography>
                                {leave.leaveValue}
                                {`${
                                  leave.carry && leave.carry.carry
                                    ? "/" + leave.carry.carry
                                    : ""
                                }`}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {utils.numberWithCommasWithoutDot(renderUseLeave(leave))}
                            </TableCell>
                            <TableCell align="center">
                              {(leave.leaveValue || (!leave.leaveValue && leave.isLeaveStep && leave.leaveValue !== null)) ?
                                utils.numberWithCommasWithoutDot(
                                  (leave.carry && leave.carry.carry
                                    ? leave.carry.carry : 0
                                  ) + leave.leaveValue - renderUseLeave(leave))
                                : "ไม่จำกัดจำนวนครั้ง"
                              }
                            </TableCell>
                          </TableRow>
                        ))
                        :
                        <TableRow>
                          <TableCell colSpan={4} style={{ minHeight: "24.5px" }}>
                            <Typography align="center">ไม่พบสิทธิการลา</Typography>
                          </TableCell>
                        </TableRow>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              }
          </StyledWrapBranch>
          <StyledHistoryLeaves>
            <Typography variant="h4" className={`cardTitle`} gutterBottom>
              ประวัติการลางาน
            </Typography>
            <div className="styledWrapHistory">
              {leaveEmployeesList && leaveEmployeesList.length > 0 ? (
                leaveEmployeesList.map((item) => (
                  <div key={item.idLeave}>
                    <CardHistoryLeave data={item} />
                  </div>
                ))
              ) : (
                <Typography color="text.secondary">ไม่มีประวัติการลางาน</Typography>
              )}
            </div>
          </StyledHistoryLeaves>
      </div>
  );
};

export default Leave;