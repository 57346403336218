import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Grid,
  styled,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Radio,
  FormControlLabel,
  Box,
  RadioGroup,
  IconButton,
} from "@mui/material";
import Dropzone, { useDropzone } from "react-dropzone";

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AdapterDateFns from "@tarzui/date-fns-be";

import AddPhotoIcon from "../../assets/add.png";
import GalleryIcon from "../../assets/gallery.png";
import ImageFileIcon from "../../assets/imagefile.png";
import DeleteIcon from "@mui/icons-material/Delete";

import utils from "../../../../utils";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import TimePicker from "../../shared/general/TimePicker";
import StatusRequest from "../../shared/general/stausRequest";

import { allHoliday } from "../../../../actions/holiday";
import { addRequestTime } from "../../../../actions/requestTime";
import { getLeaveQuota, getLeaveRequest } from "../../../../actions/employee";
import { getLeaveAvailable, getPatternForLeave } from "../../../../actions/leave";

import DialogConfirmLeave from "./dialogConfirmLeave";
import { getWorkingHoursByIdEmployees } from "../../../../actions/attendance";
import AlertResponse from "../../shared/general/AlertResponse";

const StyledRoot = styled("div")({
  width: 350,
  padding: 24,
  "& .wrap-status": {
    marginTop: 4,
    display: "flex",
    alignItems: "center",
    "& .MuiBox-root": {
      marginRight: 8,
    },
  },
  "& .wrap-footer-btn": {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 16,
  },
});

const StyledDropzone = styled(Box)({
  width: "100%",
  marginBottom: 12,
  backgroundColor: "#f9f9fb",
  "& .dropzone-leave": {
    // height: 98,
    borderRadius: 4,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },

  "& .inner-dropzone": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    // height: 90,
    outline: "none",
    display: "flex",
    overflow: "hidden",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "#efefef",
    flexDirection: "column",
    padding: "4px 0",
    "& img": {
      width: 72,
      height: 72,
    },
  },
  "& .inner-dropzone:hover .placeholder": {
    zIndex: 9,
  },
});

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiInputBase-input": {
      padding: "13.5px 14.5px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
  "& .MuiInputBase-fullWidth": {
    padding: "13.5px 14.5px",
  },
});

const StyledFormControl = styled(FormControl)({
  marginBottom: 8,
  "& .MuiInputLabel-root ": {
    color: "#a3a3a3",
  },
  "& .MuiFormLabel-filled, .Mui-focused": {
    color: "#212b36",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& .MuiSelect-select": {
    padding: "13.5px 14.5px",
  },
  "& .Mui-checked": {
    color: "#007afe !important",
  },
});

const StyledWrapTimePicker = styled("div")({
  width: "100%",
  "&.hours": {
    marginRight: 16,
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& .MuiSelect-select": {
    padding: "13.5px 14.5px",
  },
});

const DialogNewLeave = (props) => {
  const { handleClose, handleChangeAlertType, handleOpenAlert, drawerConfig, leaveRoundConfig } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const today = dayjs().toDate();
  dayjs.extend(isBetween);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(customParseFormat);
  const { result: leaveTypeList } = useSelector((state) => state.leaveType);
  const { result: leavePatternList } = useSelector(
    (state) => state.leavePattern
  );
  const { result: leaveAvailable } = useSelector((state) => state.leaveAvailable);
  const { result: holiday } = useSelector((state) => state.holiday);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [requestLeave, setRequestLeave] = React.useState({
    idLeaveType: 0,
    startDate: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0
    ),
    endDate: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0
    ),
    description: "",
    leaveDayType: "fullday",
    startDatehour: 0,
    startDateminute: 0,
    endDatehour: 0,
    endDateminute: 0,
  });
  const [openConfirm, setOpenConfirm] = useState(false);
  const [fileSelected, setFileSelected] = useState([]);

  const [errorConfig, setErrorConfig] = useState({
    isInvalidStartEnd: false,
    isOverResetEndOfMonth: false,
    isNotLeaveRoundEnd: false,
  });

  useEffect(() => {
    if (userProfile) {

      let dayCheck = dayjs(new Date());

      if (dayCheck.isAfter(dayjs(leaveRoundConfig.end))) {
        dayCheck = dayjs(leaveRoundConfig.end);
      }

      dayCheck = dayCheck.toDate();

      setOpenConfirm(false);
      setRequestLeave({
        idLeaveType: 0,
        startDate: new Date(
          dayCheck.getFullYear(),
          dayCheck.getMonth(),
          dayCheck.getDate(),
          0,
          0,
          0
        ),
        endDate: new Date(
          dayCheck.getFullYear(),
          dayCheck.getMonth(),
          dayCheck.getDate(),
          0,
          0,
          0
        ),
        description: "",
        leaveDayType: "fullday",
        startDatehour: 0,
        startDateminute: 0,
        endDatehour: 0,
        endDateminute: 0,
      });
      dispatch(
        getWorkingHoursByIdEmployees(userProfile.idEmp, {
          startDate: dayjs(dayCheck).format("YYYY-MM-DD"),
          endDate: dayjs(dayCheck).format("YYYY-MM-DD")
        })
      )
      setErrorConfig((prev) => ({
        ...prev,
        isInvalidStartEnd: false,
        isOverResetEndOfMonth: false,
        isNotLeaveRoundEnd: false,
      }));
    }
  }, [drawerConfig.isOpen, userProfile]);

  useEffect(() => {
    if (userProfile) {
      dispatch(allHoliday(userProfile.idCompany, dayjs(new Date()).format("YYYY")));
      dispatch(getLeaveQuota());
      // dispatch(getLeaveRequest(requestLeave.startDate));
      dispatch(getLeaveAvailable({ date: dayjs(today).format("YYYY-MM-DD"), idEmp: userProfile.idEmp }));
    }
  }, [userProfile]);

  useEffect(() => {
    dispatch(getPatternForLeave(requestLeave));
  }, [requestLeave.startDate, requestLeave.endDate]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: (acceptedFiles) => {
      let formData = new FormData();
      acceptedFiles.map((file) => formData.append("file", file));
      setFileSelected(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    maxFiles: 1,
  });

  const thumbs = fileSelected.map((file) => (
    <img key={file.name} src={file.preview} />
  ));

  const handleChange = (event) => {
    const temp = { ...requestLeave };
    if (event.target.name === "description") {
      temp.description = event.target.value;
    } else {
      var dataTimeStart = temp.startDate;
      dataTimeStart = dayjs(dataTimeStart).hour(0);
      dataTimeStart = dayjs(dataTimeStart).minute(0);

      var dataTimeEnd = temp.endDate;
      dataTimeEnd = dayjs(dataTimeEnd).hour(0);
      dataTimeEnd = dayjs(dataTimeEnd).minute(0);

      temp[event.target.name] = event.target.value;
      temp.startDate = dataTimeStart;
      temp.endDate = dataTimeEnd;

      if (event.target.name !== "leaveDayType") {
        temp.leaveDayType = "fullday";
      } else {
        temp.leaveDayType = event.target.value;
      };
    };

    setRequestLeave(temp);
    calculateLeaveUse(temp);
  };

  const handleChangeTime = (name, unit, periodTime, value) => {
    if (unit === "hour") {
      var dataTime = dayjs(requestLeave[name]);
      dataTime = dayjs(dataTime).hour(value);
      dataTime = dayjs(dataTime).second(0);
      setRequestLeave({
        ...requestLeave,
        [name]: dataTime,
      });
    } else {
      var dataTime = dayjs(requestLeave[name]);
      dataTime = dayjs(dataTime).minute(value);
      dataTime = dayjs(dataTime).second(0);
      setRequestLeave({
        ...requestLeave,
        [name]: dataTime,
      });
    }
  };

  const disabledButtonSubmit = () => {
    if (requestLeave.idLeaveType === 0) {
      return true;
    } else if (leaveAvailable && (displayErrorRequest() || displayErrorRequestOver())) {
      return true;
    } else if (requestLeave.leaveDayType === "halfday") {
      const areDatesEqual = dayjs(requestLeave.startDate).format("YYYY-MM-DD HH:mm:ss") === dayjs(requestLeave.endDate).format("YYYY-MM-DD HH:mm:ss");
      if (areDatesEqual) {
        return true;
      }
    } else {
      if (dayjs(requestLeave.endDate).isBefore(dayjs(requestLeave.startDate))) {
        return true;
      } else {
        return false;
      }
    }
  };

  const disabledSelectLeaveDayType = () => {
    if (requestLeave.idLeaveType === 0) {
      return true;
    } else {
      if (
        leaveAvailable && leaveAvailable.find(
          (item) => item.idLeaveType === requestLeave.idLeaveType
        ) && leaveAvailable.find(
          (item) => item.idLeaveType === requestLeave.idLeaveType
        ).minLeave === 100
      ) {
        return true;
      } else {

        const findShiftType = leavePatternList && leavePatternList.find(item => item.date === dayjs(requestLeave.startDate).format("YYYY-MM-DD"));
        const checkedNightShift = findShiftType && findShiftType.pattern && findShiftType.pattern.period;

        if (
          !(dayjs(requestLeave.startDate).isSame(requestLeave.endDate, "date") || checkedNightShift === 3)
        ) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const displayNumberLeaveDays = () => {
    if (
      requestLeave.idLeaveType === 0 ||
      !leavePatternList ||
      leavePatternList.length <= 0 ||
      !leaveAvailable ||
      leaveAvailable.length <= 0
    ) {
      return `0.00 `;
    } else {
      if (requestLeave.leaveDayType === "fullday") {
        if (
          dayjs(requestLeave.startDate).isSame(requestLeave.endDate, "date")
        ) {
          return utils.numberWithCommas(1);
        } else {
          const date1 = dayjs(requestLeave.endDate);
          const diff = date1.diff(requestLeave.startDate, "day");
          let sum = diff + 1;
          let leaveType = leaveAvailable && leaveAvailable.find((leave) => leave.idLeaveType === requestLeave.idLeaveType);

          if (leaveType && leaveType.isWeekend) {
            let patternEmployee = leavePatternList[1].pattern;
            if (patternEmployee.idWorkingType === 2) {
              for (let i = 0; i < diff + 1; i++) {
                let indexDay = parseInt(
                  dayjs(requestLeave.startDate).add(i, "day").format("d")
                );
                if (
                  (patternEmployee.workDay === 5 &&
                    (indexDay === 0 || indexDay === 6)) ||
                  (patternEmployee.workDay === 6 && indexDay === 0)
                ) {
                  sum -= 1;
                }
              }
            }
          }
          return utils.numberWithCommas(sum);
        }
      } else {
        if (
          dayjs(requestLeave.startDate).isSame(requestLeave.endDate, "minute")
        ) {
          return utils.numberWithCommas(0);
        } else {
          const date1 = dayjs(requestLeave.endDate);
          const diff = date1.diff(requestLeave.startDate, "minute");
          if (diff < 0) {
            return utils.numberWithCommas(0);
          } else {
            if (
              dayjs(requestLeave.endDate).isBetween(
                dayjs(requestLeave.endDate)
                  .set("hour", 12)
                  .set("minute", 0)
                  .set("second", 0),
                dayjs(requestLeave.endDate)
                  .set("hour", 12)
                  .set("minute", 59)
                  .set("second", 59),
                "minute",
                "(]"
              )
            ) {
              return utils.numberWithCommas((9 * 60) / 2 / 60 / 8);
            } else if (
              dayjs(requestLeave.endDate).isSameOrAfter(
                dayjs(requestLeave.endDate)
                  .set("hour", 12)
                  .set("minute", 59)
                  .set("second", 59),
                "minute"
              )
            ) {
              const findShiftType = leavePatternList && leavePatternList.find(item => item.date === dayjs(requestLeave.startDate).format("YYYY-MM-DD"));
              const checkedNightShift = findShiftType && findShiftType.pattern && findShiftType.pattern.period;
              if (checkedNightShift === 3) {
                return utils.numberWithCommas((diff / 60) / 8);
              } else {
                const pattern = findShiftType.pattern;
                if (pattern && pattern.breakTime === 0) {
                  let breakMin = pattern.breakTimeMin || 60;

                  const leaveStart = dayjs(requestLeave.startDate);
                  const leaveEnd = dayjs(requestLeave.endDate);
                  const breakStart = dayjs(leaveStart.format("YYYY-MM-DD") + ' ' + pattern.startBreak, 'YYYY-MM-DD HH:mm:ss');
                  const breakEnd = breakStart.add(breakMin, 'minute');

                  let totalLeaveDuration = leaveEnd.diff(leaveStart, 'minute');

                  if (leaveEnd.isBetween(breakStart, breakEnd, null, '[)')) {
                    const overlap = leaveEnd.diff(breakStart, 'minute');
                    totalLeaveDuration -= overlap;
                  } else if (leaveStart.isBetween(breakStart, breakEnd, null, '[)')) {
                    const overlap = breakEnd.diff(leaveStart, 'minute');
                    totalLeaveDuration -= overlap;
                  } else if (leaveStart.isBefore(breakStart) && leaveEnd.isAfter(breakEnd)) {
                    totalLeaveDuration -= breakMin;
                  };

                  if (totalLeaveDuration < 0) totalLeaveDuration = 0;
                  return utils.numberWithCommas((totalLeaveDuration / 60) / 8);
                } else {
                  return utils.numberWithCommas((diff / (60 - 1)) / 8);
                };
              };
            } else {
              return utils.numberWithCommas(diff / 60 / 8);
            }
          }
        }
      }
    }
  };

  const displayNumberLeaveHours = () => {
    if (requestLeave.idLeaveType === 0) {
      return `0.00 `;
    } else {
      if (requestLeave.leaveDayType === "fullday") {
        if (
          dayjs(requestLeave.startDate).isSame(requestLeave.endDate, "date")
        ) {
          return utils.numberWithCommas(8);
        } else {
          const date1 = dayjs(requestLeave.endDate);
          const diff = date1.diff(requestLeave.startDate, "day");
          return utils.numberWithCommas((diff + 1) * 8);
        }
      } else {
        if (
          dayjs(requestLeave.startDate).isSame(requestLeave.endDate, "minute")
        ) {
          return utils.numberWithCommas(8);
        } else {
          const date1 = dayjs(requestLeave.endDate);
          const diff = date1.diff(requestLeave.startDate, "minute");
          if (diff < 0) {
            return utils.numberWithCommas(0);
          } else {
            if (
              dayjs(requestLeave.endDate).isBetween(
                dayjs(requestLeave.endDate)
                  .set("hour", 12)
                  .set("minute", 0)
                  .set("second", 0),
                dayjs(requestLeave.endDate)
                  .set("hour", 13)
                  .set("minute", 0)
                  .set("second", 0),
                "minute",
                "[]"
              )
            ) {
              return utils.numberWithCommas((9 * 60) / 2 / 60);
            } else if (
              dayjs(requestLeave.endDate).isSameOrAfter(
                dayjs(requestLeave.endDate)
                  .set("hour", 12)
                  .set("minute", 59)
                  .set("second", 59),
                "minute"
              ) &&
              dayjs(requestLeave.startDate).isSameOrBefore(
                dayjs(requestLeave.startDate)
                  .set("hour", 12)
                  .set("minute", 59)
                  .set("second", 59),
                "minute"
              )
            ) {
              return utils.numberWithCommas(diff / 60 - 1);
            } else {
              return utils.numberWithCommas(diff / 60);
            }
          }
        }
      }
    }
  };

  const displayErrorRequest = () => {
    const found = leaveAvailable.find(
      (x) => x.idLeaveType === requestLeave.idLeaveType
    );

    if (found && found.canRequestAfter && userProfile && dayjs().diff(dayjs(userProfile.hiringDate), 'days') <= found.canRequestAfter) {
      return `*อายุงานไม่ถึงขั้นต่ำที่กำหนด`;
    }

    if (requestLeave.leaveDayType === "halfday") {
      if (
        !dayjs(requestLeave.startDate).isSame(requestLeave.endDate, "minute")
      ) {
        const date1 = dayjs(requestLeave.endDate);
        const diff = date1.diff(requestLeave.startDate, "minute");
        if (diff < 0) {
          return `*ลาไม่ถึงขั้นต่ำที่กำหนด`;
        } else {
          if (found.minLeave === 50) {
            if (diff <= 270) {
              return `*ลาไม่ถึงขั้นต่ำที่กำหนด`;
            }
          }
        }
      }
    }
  };

  const displayErrorRequestOver = () => {
    const found = leaveAvailable.find((x) => x.idLeaveType === requestLeave.idLeaveType);

    if (found && found.leaveValue) {
      let textRemain = displayRemaining();
      let remain = null;
      if (textRemain.indexOf(" วัน") >= 0) {
        remain = parseFloat(textRemain.split(" วัน")[0]);
      }

      if (!isNaN(remain) && remain < parseFloat(displayNumberLeaveDays())) {
        // console.log("result", found.carryValue + found.leaveValue - sum)
        // console.log("displayNumberLeaveDays", displayNumberLeaveDays())
        return `*สิทธิการลาไม่เพียงพอ`;
      }
    }
  };

  const displayRemaining = () => {
    let sum = 0;

    if (leaveAvailable && leaveAvailable.length > 0) {
      const foundLeaveType = leaveAvailable.find((x) => x.idLeaveType === requestLeave.idLeaveType);
      sum = (foundLeaveType && foundLeaveType.leaveUsed) || 0;
    };

    const found = leaveAvailable.find((item) => item.idLeaveType === requestLeave.idLeaveType);

    if (found && found.leaveValue === null) {
      return `ไม่ระบุ`;
    } else {
      if (leaveAvailable.length === 0) {
        return found
          ? `${utils.numberWithCommas(
            (found.leaveRemain ? found.leaveRemain : 0) + (found.carryRemain ? found.carryRemain : 0)
          )} วัน`
          : `${utils.numberWithCommas(0)} วัน`;
      } else {
        return found
          ? `${utils.numberWithCommas(
            (found.leaveRemain ? found.leaveRemain : 0) + (found.carryRemain ? found.carryRemain : 0)
          )} วัน`
          : `${utils.numberWithCommas(0)} วัน`;
      }
    }
  };

  const calculateLeaveUse = (requestLeave) => {
    setErrorConfig((prev) => ({
      ...prev,
      isInvalidStartEnd: false,
      isOverResetEndOfMonth: false,
      isNotLeaveRoundEnd: false,
    }));

    // if (requestLeave.leaveDayType === "fullday") {

      let start = dayjs(requestLeave.startDate);
      let end = dayjs(requestLeave.endDate);

      start
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .set("millisecond", 0);
      end
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .set("millisecond", 0);
      
      if (leaveRoundConfig) {
        if (
          !start.isBetween(
            dayjs(leaveRoundConfig.start),
            dayjs(leaveRoundConfig.end),
            "day",
            "[]"
          ) || !end.isBetween(
            dayjs(leaveRoundConfig.start),
            dayjs(leaveRoundConfig.end),
            "day",
            "[]"
          )
        ) {
          setErrorConfig((prev) => ({
            ...prev,
            isNotLeaveRoundEnd: true,
          }));
        }
      }

      // if (leaveSetting) {
      //   let dayResetEndOfMonthOfStart = dayjs()
      //     .set(
      //       "month",
      //       leaveSetting.resetEndOfMonth === null
      //         ? 11
      //         : leaveSetting.resetEndOfMonth - 1
      //     )
      //     .set("year", start.get("year"));
      //   dayResetEndOfMonthOfStart = dayResetEndOfMonthOfStart.set(
      //     "date",
      //     dayResetEndOfMonthOfStart.daysInMonth()
      //   );

      //   // console.log(dayResetEndOfMonth.format("YYYY-MM-DD"))
      //   if (dayResetEndOfMonthOfStart.isBetween(start, end, "day", "[)")) {
      //     setErrorConfig((prev) => ({
      //       ...prev,
      //       isOverResetEndOfMonth: true,
      //     }));
      //   }

      //   let dayResetEndOfMonthOfEnd = dayjs()
      //     .set(
      //       "month",
      //       leaveSetting.resetEndOfMonth === null
      //         ? 11
      //         : leaveSetting.resetEndOfMonth - 1
      //     )
      //     .set("year", end.get("year"));
      //   dayResetEndOfMonthOfEnd = dayResetEndOfMonthOfEnd.set(
      //     "date",
      //     dayResetEndOfMonthOfEnd.daysInMonth()
      //   );

      //   // console.log(dayResetEndOfMonth.format("YYYY-MM-DD"))
      //   if (dayResetEndOfMonthOfEnd.isBetween(start, end, "day", "[)")) {
      //     setErrorConfig((prev) => ({
      //       ...prev,
      //       isOverResetEndOfMonth: true,
      //     }));
      //   }
      // }

    // } else {

    // }

  }

  const isError = () => {
    let isInvalid = false;
    for (const [_, value] of Object.entries(errorConfig)) {
      if (value) isInvalid = true;
    }
    return isInvalid;
  };

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [alertLabel, setAlertLabel] = useState("");

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const CompareSelectedDateWithShift = () => {
    const startDate = dayjs(requestLeave.startDate);
    const endDate = dayjs(requestLeave.endDate);

    const checkForHoliday = (date) =>
      holiday && holiday.some(holiday => holiday.formattedDate === date);

    const handleAlert = (messageKey) => {
      setAlertLabel(t(messageKey));
      setOpenAlert(true);
      setAlertType("error");
    };

    const isNonWorkingDay = (date) => {
      const foundDate = leavePatternList && leavePatternList.find(leave => leave.date === date);
      const pattern = foundDate && foundDate.pattern;
      return pattern && pattern.isWorkingDay === 0;
    };

    if (requestLeave.leaveDayType === "fullday") {
      let validLeaveDays = [];

      const dayList = [];
      for (let currentDate = startDate; currentDate.isBefore(endDate) || currentDate.isSame(endDate); currentDate = currentDate.add(1, 'day')) {
        dayList.push(currentDate.format('YYYY-MM-DD'));
      }

      for (const date of dayList) {
        if (isNonWorkingDay(date) || checkForHoliday(date)) {
          continue; // Skip non-working or holiday dates
        }
        validLeaveDays.push(date);
      }

      if (validLeaveDays.length === 0) {
        handleAlert("UnableRequestLeave");
      } else {
        setOpenConfirm(true);
      }

    } else {
      const formattedStartDate = startDate.format("YYYY-MM-DD");

      const findShiftType = leavePatternList && leavePatternList.find(item => item.date === dayjs(requestLeave.startDate).format("YYYY-MM-DD"));
      const checkedNightShift = findShiftType && findShiftType.pattern && findShiftType.pattern.period;

      if (isNonWorkingDay(formattedStartDate) || checkForHoliday(formattedStartDate)) {
        return handleAlert("UnableRequestLeave");
      }

      const foundDate = leavePatternList && leavePatternList.find(leave => leave.date === formattedStartDate);
      const pattern = foundDate && foundDate.pattern;

      if (pattern) {
        const requestedStartTime = dayjs(startDate.format("HH:mm:ss"), "HH:mm:ss");
        const requestedEndTime = dayjs(endDate.format("HH:mm:ss"), "HH:mm:ss");

        const patternStartTime = dayjs(pattern.timeIn, "HH:mm:ss");
        const patternEndTime = dayjs(pattern.timeOut, "HH:mm:ss");

        // Night Shift Logic
        if (checkedNightShift === 3) {
          const formattedStartDate = dayjs(requestLeave.startDate).startOf('day');
          const formattedEndDate = dayjs(requestLeave.endDate).startOf('day');
          const isEndDateAfterStartDate = formattedEndDate.isAfter(formattedStartDate);

          if (isEndDateAfterStartDate) {
            const requestedNightStartTime = dayjs(startDate.format("HH:mm:ss"), "HH:mm:ss");
            const formatRequestedNightEndTime = dayjs(endDate).subtract(1, 'day');
            const requestedNightEndTime = dayjs(formatRequestedNightEndTime.format("HH:mm:ss"), "HH:mm:ss");

            if (requestedNightStartTime.isSameOrAfter(patternStartTime) && requestedNightEndTime.isSameOrBefore(patternEndTime)) {
              return checkBreakTimeAndConfirmLeave(requestedStartTime, requestedEndTime, pattern, handleAlert, setOpenConfirm);
            } else {
              return handleAlert("RequestedTimeOutsidAllowedTime");
            }
          } else {
            const patternEndTime = dayjs(pattern.timeOut, "HH:mm:ss").add(1, 'day');
            if (
              requestedStartTime.isBetween(patternStartTime, patternEndTime, null, '[]') &&
              requestedEndTime.isBetween(patternStartTime, patternEndTime, null, '[]')
            ) {
              return checkBreakTimeAndConfirmLeave(requestedStartTime, requestedEndTime, pattern, handleAlert, setOpenConfirm);
            } else {
              return handleAlert("RequestedTimeOutsidAllowedTime");
            }
          };
        } else {
          // Non-Night Shift Logic
          if (
            requestedStartTime.isBetween(patternStartTime, patternEndTime, null, '[]') &&
            requestedEndTime.isBetween(patternStartTime, patternEndTime, null, '[]')
          ) {
            return checkBreakTimeAndConfirmLeave(requestedStartTime, requestedEndTime, pattern, handleAlert, setOpenConfirm);
          } else {
            return handleAlert("RequestedTimeOutsidAllowedTime");
          }
        }
      }
    }
  };

  function checkBreakTimeAndConfirmLeave(requestedStartTime, requestedEndTime, pattern, handleAlert, setOpenConfirm) {
    if (!pattern.breakTime || pattern.breakTime === 0) {
      const startBreakTime = dayjs(pattern.startBreak, "HH:mm:ss");
      const endBreakTime = startBreakTime.add(pattern.breakTimeMin / 60, 'hour');

      if (
        requestedStartTime.isBetween(startBreakTime, endBreakTime, null, '[]') &&
        requestedEndTime.isBetween(startBreakTime, endBreakTime, null, '[]')
      ) {
        return handleAlert("UnableRequestLeaveduringBreakTime");
      }
    }
    setOpenConfirm(true);
  };

  return (
    <DrawerCustom
      title={t("CreateLeave")}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={handleClose}
    >
      {openConfirm ? (
        <DialogConfirmLeave
          data={requestLeave}
          handleClose={handleClose}
          handleOpenAlert={handleOpenAlert}
          handleChangeAlertType={handleChangeAlertType}
          openConfirm={setOpenConfirm}
          leaveRoundConfig={leaveRoundConfig}
        />
      ) : (
        <StyledRoot>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div>
                {leaveAvailable && (
                  <StyledFormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      {t("LeaveType")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={requestLeave.idLeaveType}
                      label={t("LeaveType")}
                      name="idLeaveType"
                      onChange={handleChange}
                    >
                      {leaveAvailable.map((item) => (
                        <MenuItem value={item.idLeaveType}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                )}
                {leaveAvailable && (
                  <StyledFormControl
                    component="fieldset"
                    style={{ marginBottom: 16 }}
                  >
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="leaveDayType"
                      value={requestLeave.leaveDayType}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="fullday"
                        control={<Radio />}
                        label={t("FullDay")}
                      />
                      <FormControlLabel
                        value="halfday"
                        control={<Radio />}
                        label={t("SpecifyTime")}
                        disabled={disabledSelectLeaveDayType()}
                      />
                    </RadioGroup>
                  </StyledFormControl>
                )}
                <Grid item xs={12}>
                  <div style={{ marginBottom: 16 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label={t("StartDate")}
                        name="startDate"
                        inputFormat="dd/MM/yyyy"
                        value={requestLeave.startDate}
                        disabled={!requestLeave.idLeaveType}
                        onChange={(newValue) => {
                          const temp = { ...requestLeave };

                          var dataTimeStart = newValue;
                          dataTimeStart = dayjs(newValue).hour(0);
                          dataTimeStart = dayjs(dataTimeStart).minute(0);

                          var dataTimeEnd = dayjs(temp.endDate).isSameOrAfter(
                            newValue
                          )
                            ? temp.endDate
                            : dayjs(newValue).isAfter(temp.startDate)
                              ? newValue
                              : temp.startDate;
                          dataTimeEnd = dayjs(dataTimeEnd).hour(0);
                          dataTimeEnd = dayjs(dataTimeEnd).minute(0);

                          temp.leaveDayType = "fullday";
                          temp.startDate = dataTimeStart;
                          temp.endDate = dataTimeEnd;
                          setRequestLeave(temp);
                          calculateLeaveUse(temp);
                        }}
                        renderInput={(params) => (
                          <StyledTextField
                            style={{ width: "100%" }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div style={{ display: "flex", marginBottom: 16 }}>
                    <StyledWrapTimePicker className="hours">
                      <TimePicker
                        handleChangeTime={handleChangeTime}
                        unit="hour"
                        name="startDate"
                        disabled={requestLeave.leaveDayType === "fullday"}
                        value={dayjs(requestLeave.startDate).hour()}
                      />
                    </StyledWrapTimePicker>
                    <StyledWrapTimePicker>
                      <TimePicker
                        handleChangeTime={handleChangeTime}
                        unit="minute"
                        name="startDate"
                        disabled={requestLeave.leaveDayType === "fullday"}
                        value={dayjs(requestLeave.startDate).minute()}
                      />
                    </StyledWrapTimePicker>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ marginBottom: 16 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label={t("EndDate")}
                        name="endDate"
                        inputFormat="dd/MM/yyyy"
                        value={requestLeave.endDate}
                        disabled={!requestLeave.idLeaveType}
                        onChange={(newValue) => {
                          const temp = { ...requestLeave };

                          var dataTimeStart = temp.startDate;
                          dataTimeStart = dayjs(dataTimeStart).hour(0);
                          dataTimeStart = dayjs(dataTimeStart).minute(0);

                          var dataTimeEnd = newValue;
                          dataTimeEnd = dayjs(newValue).hour(0);
                          dataTimeEnd = dayjs(dataTimeEnd).minute(0);

                          const findShiftType = leavePatternList && leavePatternList.find(item => item.date === dayjs(requestLeave.startDate).format("YYYY-MM-DD"));
                          const checkedNightShift = findShiftType && findShiftType.pattern && findShiftType.pattern.period;

                          if (checkedNightShift !== 3) {
                            // temp.leaveDayType
                            temp.leaveDayType = "fullday";
                          }

                          temp.startDate = dataTimeStart;
                          temp.endDate = dataTimeEnd;

                          setRequestLeave(temp);
                          calculateLeaveUse(temp);
                        }}
                        renderInput={(params) => (
                          <StyledTextField
                            style={{ width: "100%" }}
                            {...params}
                          />
                        )}
                      // minDate={
                      //   new Date(
                      //     dayjs(requestLeave.startDate).format(
                      //       "YYYY-MM-DD"
                      //     )
                      //   )
                      // }
                      />
                    </LocalizationProvider>
                  </div>
                  <div style={{ display: "flex", marginBottom: 16 }}>
                    <StyledWrapTimePicker className="hours">
                      <TimePicker
                        handleChangeTime={handleChangeTime}
                        unit="hour"
                        name="endDate"
                        disabled={requestLeave.leaveDayType === "fullday"}
                        value={dayjs(requestLeave.endDate).hour()}
                      />
                    </StyledWrapTimePicker>
                    <StyledWrapTimePicker>
                      <TimePicker
                        handleChangeTime={handleChangeTime}
                        unit="minute"
                        // step={1}
                        // minStep={0}
                        // maxStep={60}
                        name="endDate"
                        disabled={requestLeave.leaveDayType === "fullday"}
                        value={dayjs(requestLeave.endDate).minute()}
                      />
                    </StyledWrapTimePicker>
                  </div>
                </Grid>

                {errorConfig.isOverResetEndOfMonth && (
                  <Grid item xs={12}>
                    <Typography fontSize="20px" fontWeight="500" color="error">
                      *ไม่สามารถลาคร่อมวันตัดรอบได้
                    </Typography>
                  </Grid>
                )}

                {errorConfig.isNotLeaveRoundEnd && (
                  <Grid item xs={12}>
                    <Typography fontSize="20px" fontWeight="500" color="error">
                      *ไม่สามารถลานอกช่วงรอบการลาที่เลือกได้
                    </Typography>
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignItems: "baseline" }}
                >
                  <div>
                    <Typography color="text.third" variant="body2">
                      {t("NumberLeaveDays")}
                    </Typography>
                    <Typography variant="h6">
                      {displayNumberLeaveDays()} วัน
                    </Typography>
                    {/* <Typography variant="h6">
                      {displayNumberLeaveDays()} วัน (
                      {displayNumberLeaveHours()}
                      ชั่วโมง)
                    </Typography> */}
                    <Typography color="text.third" variant="body2">
                      {t("RemainingLeaveRights")}
                    </Typography>
                    <Typography variant="h6">
                      {leaveAvailable &&
                        displayRemaining()}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ color: "#f15e5e", fontWeight: 500 }}
                    >
                      {leaveAvailable && displayErrorRequest()}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ color: "#f15e5e", fontWeight: 500 }}
                    >
                      {leaveAvailable &&
                        displayErrorRequestOver()}
                    </Typography>
                  </div>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                fullWidth
                id="description"
                label={t("Description")}
                multiline
                rows={4}
                name="description"
                value={requestLeave.description}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                color="text.third"
                variant="body2"
                paddingBottom="16px"
              >
                {t("AttachFile")}
              </Typography>
              {!requestLeave.file && (
                <StyledDropzone>
                  <Dropzone
                    accept="image/jpeg, image/png"
                    maxFiles={1}
                    multiple={false}
                    maxSize={3145728}
                    onDrop={(acceptedFiles, rejectedFiles) => {
                      if (acceptedFiles.length > 0) {
                        setRequestLeave({
                          ...requestLeave,
                          file: acceptedFiles[0],
                        });
                      }
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ className: "dropzone-leave" })}>
                        <div className="inner-dropzone">
                          <input {...getInputProps()} />
                          {/* <Fragment>{thumbs}</Fragment> */}
                          <div style={{ textAlign: "center" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <img alt="add" src={GalleryIcon} />
                            </div>
                            <Typography
                              variant="caption"
                              style={{ lineHeight: 0.5, color: "#999999" }}
                            >
                              Allowed *.jpeg, *.jpg, *.png, max size of 3 MB
                            </Typography>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </StyledDropzone>
              )}

              {requestLeave.file && (
                <Box style={{ position: "relative", textAlign: "center" }}>
                  <IconButton
                    style={{ position: "absolute", top: -8, right: 0 }}
                    color="error"
                    onClick={() => {
                      setRequestLeave({ ...requestLeave, file: null });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <div>
                    {/* <img src={ImageFileIcon} width="40" /> */}
                    <Typography>{requestLeave.file.path}</Typography>
                  </div>
                  {/* <img
                    alt="attach-file"
                    src={URL.createObjectURL(requestLeave.file)}
                    style={{
                      maxWidth: 300,
                      maxHeight: 128,
                      objectFit: "contain",
                    }}
                  /> */}
                </Box>
              )}
            </Grid>
          </Grid>
          <div className="wrap-footer-btn">
            <ButtonBlue
              size=""
              variant={"text"}
              color="secondary"
              onClick={handleClose}
            >
              {t("Cancel")}
            </ButtonBlue>
            {/* <ButtonBlue
              size=""
              variant={"contained"}
              onClick={() => {
                setOpenConfirm(true);
              }}
              disabled={disabledButtonSubmit()}
            >
              {t("CreateLeave")}
            </ButtonBlue> */}

            <ButtonBlue
              size=""
              variant={"contained"}
              onClick={() => CompareSelectedDateWithShift()}
              disabled={disabledButtonSubmit() || isError()}
            >
              {t("CreateLeave")}
            </ButtonBlue>
          </div>
          <AlertResponse
            open={openAlert}
            handleClose={handleCloseAlert}
            alertType={alertType}
            label={alertLabel}
          />
        </StyledRoot>
      )}
    </DrawerCustom>
  );
};

export default DialogNewLeave;
