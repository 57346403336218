import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  styled,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from 'react-hook-form';

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import customParseFormat from "dayjs/plugin/customParseFormat";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import ButtonBlue from "../general/ButtonBlue";
import DrawerCustom from "../general/Drawer";
import DatePickerEng from "../general/DatePickerEng";
import DatePickerThai from "../general/DatePickerThai";
import TimePicker from "../general/TimePicker";

import {
  addRequestTime,
  updateRequestTime,
  getVendorRequestReason,
} from "../../../../actions/requestTime";
import {
  getAttendanceById,
  getAttendanceCompanyByIdEmp,
} from "../../../../actions/attendance";
import { getPayrollSetting } from "../../../../actions/paytypes";
import StatusRequest from "../general/stausRequest";
import CalculateOTFunction from "./calculateOT";
import calculateService from "../../../../services/calculate.service";

const StyledRoot = styled("div")({
  width: 350,
  padding: 24,
  ["@media only screen and (max-width: 375px)"]: {
    width: 320,
  },
  "& .wrap-status": {
    marginTop: 4,
    display: "flex",
    alignItems: "center",
    "& .MuiBox-root": {
      marginRight: 8,
    },
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledWrapHoursOT = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .label": {
    fontWeight: 500,
  },
});

const DialogNewRequest = (props) => {
  const {
    values,
    open,
    handleCloseDialog,
    handleChangeAlertType,
    handleOpenAlert,
    handleOpenDialogConfirmDelete,
    searchDate,
    attendanceListState,
    employeeProfile,
    isUserTimelinePage,
    isFillIn,
    idRoles
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  dayjs.extend(customParseFormat);
  dayjs.extend(isBetween);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);
  const { user: authUser } = useSelector((state) => state.auth);
  const { result: requestTimeReasonList } = useSelector(
    (state) => state.requestTimeReason
  );
  const { control, setValue, watch, reset } = useForm({
    defaultValues:{
      idRequestType: "",
      startDate: dayjs(),
      startHours: "",
      startMinute: "",
      endDate: dayjs(),
      endHours: "",
      endMinute: "",
      idRequestReason: "",
      otherReason: "",
    }
  });

  const [mode, setMode] = useState("read");
  const [isSubmit, setIsSubmit] = useState(false);
  const [summaryOT, setSummaryOT] = useState(null);

  useEffect(() => {
    dispatch(
      getVendorRequestReason({
        idVendor: employeeProfile.idVendor,
        idCompany: employeeProfile.idCompany,
      })
    );
    if (!isUserTimelinePage) {
      dispatch(getPayrollSetting());
    }
  }, []);

  useEffect(() => {
    if (values.idRequestTime) {
      reset({
        idRequestTime: values.idRequestTime,
        idRequestType: values.idRequestType,
        startDate: dayjs(values.startText, "DD/MM/YYYY HH:mm"),
        startHours: dayjs(values.startText, "DD/MM/YYYY HH:mm").format("H"),
        startMinute: dayjs(values.startText, "DD/MM/YYYY HH:mm").format("m"),
        endDate: dayjs(values.endText, "DD/MM/YYYY HH:mm"),
        endHours: dayjs(values.endText, "DD/MM/YYYY HH:mm").format("H"),
        endMinute: dayjs(values.endText, "DD/MM/YYYY HH:mm").format("m"),
        managerLV1Id: values.managerLV1Id,
        idRequestReason: values.idRequestReason,
        otherReason: values.otherReason,
        isDoubleApproval: values.isDoubleApproval,
        approvalLevel: values.approvalLevel,
        managerLV1Name: values.managerLV1Name,
        isManagerLV1Approve: values.isManagerLV1Approve,
        managerLV1ApproveDate: values.managerLV1ApproveDate
          ? dayjs(values.managerLV1ApproveDate).format("DD/MM/BBBB HH:mm")
          : null,
        managerLV1ApproveDateText: values.managerLV1ApproveDateText,
        positionManagerLV1: values.positionManagerLV1,
        managerLV2Name: values.managerLV2Name,
        isManagerLV2Approve: values.isManagerLV2Approve,
        managerLV2ApproveDate: values.managerLV2ApproveDate
          ? dayjs(values.managerLV2ApproveDate).format("DD/MM/BBBB HH:mm")
          : null,
        managerLV2ApproveDateText: values.managerLV2ApproveDateText,
        positionManagerLV2: values.positionManagerLV2,
        createDateText: values.createDateText,
      });
      setMode("Edit");
    } else {
      setMode("write");
      reset({
        idRequestType: "",
        startDate: dayjs(values.date, "YYYY-MM-DD"),
        startHours: "",
        startMinute: "",
        endDate: dayjs(values.date, "YYYY-MM-DD"),
        endHours: "",
        endMinute: "",
        idRequestReason: "",
        otherReason: "",
      });
    }
  }, [values]);

  useEffect(async () => {
    if(watch("idRequestType") === 2){
      if(
        watch("startDate") &&
        watch("startHours") !== "" &&
        watch("startMinute") !== "" &&
        watch("endDate") &&
        watch("endHours") !== "" &&
        watch("endMinute") !== "" &&
        watch("idRequestReason") !== ""
      ){
        setSummaryOT(null);
        let requestReason = requestTimeReasonList ? requestTimeReasonList.find(reason => reason.idRequestReason === watch("idRequestReason")) : null;
        let resultOT = await calculateService.calculateOT(
          values.date,
          dayjs(watch("startDate"))
            .set("hour", watch("startHours"))
            .set("minute", watch("startMinute"))
            .set("second", 0).format("YYYY-MM-DD HH:mm"),
          dayjs(watch("endDate"))
            .set("hour", watch("endHours"))
            .set("minute", watch("endMinute"))
            .set("second", 0).format("YYYY-MM-DD HH:mm"),
          {
            ...values,
            isReasonBreak: (requestReason && requestReason.isBreak === 1)
            ? true : false
          }
        )
        .then((res) => {
          if(res.status === 200){
            return res.data;
          }else{
            return null;
          }
        });

        if(!resultOT){
          return;
        }

        let ObjectOt = {};
        let ObjectTypeOT = {};
        
        if(resultOT.xOTMinute > 0 && resultOT.xOT && resultOT.xOT > 0){
          ObjectOt[resultOT.xOT] = resultOT.xOTMinute / 60 + (ObjectOt[resultOT.xOT] || 0);
          ObjectTypeOT["xOT"]  = resultOT.xOTMinute;
        }

        if(resultOT.xOTHolidayMinute > 0 && resultOT.xOTHoliday && resultOT.xOTHoliday > 0){
          ObjectOt[resultOT.xOTHoliday] = resultOT.xOTHolidayMinute / 60 + (ObjectOt[resultOT.xOTHoliday] || 0);
          ObjectTypeOT["xOTHoliday"]  = resultOT.xOTHolidayMinute;
        }

        if(resultOT.xWorkingDailyHolidayMinute > 0 && resultOT.xWorkingDailyHoliday && resultOT.xWorkingDailyHoliday > 0){
          ObjectOt[resultOT.xWorkingDailyHoliday] = resultOT.xWorkingDailyHolidayMinute / 60 + (ObjectOt[resultOT.xWorkingDailyHoliday] || 0);
          ObjectTypeOT["xWorkingDailyHoliday"]  = resultOT.xWorkingDailyHolidayMinute;
        }

        if(resultOT.xWorkingMonthlyHolidayMinute > 0 && resultOT.xWorkingMonthlyHoliday && resultOT.xWorkingMonthlyHoliday > 0){
          ObjectOt[resultOT.xWorkingMonthlyHoliday] = resultOT.xWorkingMonthlyHolidayMinute / 60 + (ObjectOt[resultOT.xWorkingMonthlyHoliday] || 0);
          ObjectTypeOT["xWorkingMonthlyHoliday"]  = resultOT.xWorkingMonthlyHolidayMinute;
        }

        if(resultOT.xWorkingDailyMinute > 0){
          ObjectOt[1] = resultOT.xWorkingDailyMinute / 60 + (ObjectOt[1] || 0);
          ObjectTypeOT["xWorkingDaily"]  = resultOT.xWorkingDailyMinute;
        }

        resultOT = {
          isErrorBreakTime : resultOT.isErrorBreakTime,
          isOutOfRange : resultOT.isOutOfRange,
          overlapWorking : resultOT.overlapWorking,
          isInTimeFrame : resultOT.isInTimeFrame,
          ObjectOt : ObjectOt,
          ObjectTypeOT: ObjectTypeOT
        }

        setSummaryOT(resultOT);
      }else{
        setSummaryOT(null);
      }
    }
    else{
      setSummaryOT(null);
    }
  },[
    watch("idRequestType"),
    watch("startDate"),
    watch("startHours"),
    watch("startMinute"),
    watch("endDate"),
    watch("endHours"),
    watch("endMinute"),
    watch("idRequestReason"),
  ]
  );

  const handleChangeTime = (name, unit, periodTime, value) => {
    setValue(name, value);
  };

  const handleSaveRequestTime = async () => {
    if (disabledBtn()) {
      alert("ข้อมูลไม่ถูกต้อง");
    } else {
      if (isSubmit === false) {
        setIsSubmit(true);

        const startDate = dayjs(watch("startDate"))
          .set("hour", parseInt(watch("startHours")))
          .set("minute", parseInt(watch("startMinute")))
          .set("second", 0);
        const endDate = dayjs(watch("endDate"))
          .set("hour", parseInt(watch("endHours")))
          .set("minute", parseInt(watch("endMinute")))
          .set("second", 0);

        const formData = {
          workDate: values.date,
          start: startDate.format("YYYY-MM-DD HH:mm:ss"),
          end: endDate.format("YYYY-MM-DD HH:mm:ss"),
          idEmp: employeeProfile.idEmp,
          isDoubleApproval: 0,
          approvalLevel: 1,
          amountHours: 0,
          xOT: 0,
          xOTHoliday: 0,
          xWorkingDaily: 0,
          xWorkingDailyHoliday: 0,
          xWorkingMonthlyHoliday: 0,
          updateBy: authUser.id,
          updateDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
          idRequestReason: parseInt(watch("idRequestReason")),
          otherReason: watch("otherReason"),
          ...(values.idRequestTime
            ? {
                idRequestTime: values.idRequestTime,
                idRequestType: parseInt(watch("idRequestType")),
              }
            : {
                idRequestType: parseInt(watch("idRequestType")),
                isManagerLV1Approve: null,
                isManagerLV2Approve: null,
                isActive: 1,
                managerLV1ApproveBy: values.manager.idManagerLV1,
                managerLV2ApproveBy: values.manager.idManagerLV2,
                createBy: authUser.id,
              }),
        };

        if(isFillIn) {
          if([3,4].includes(idRoles)){
            formData.fillInApproveLV1 = authUser.id
            formData.isManagerLV1Approve = true
            formData.fillInApproveLV2 = authUser.id
            formData.isManagerLV2Approve = true
          }
          formData.fillInCreate = authUser.id
        }

        if(summaryOT && formData.idRequestType === 2){
          formData.xOT = summaryOT.ObjectTypeOT.xOT || 0;
          formData.xOTHoliday = summaryOT.ObjectTypeOT.xOTHoliday || 0;
          formData.xWorkingDaily = summaryOT.ObjectTypeOT.xWorkingDaily || 0;
          formData.xWorkingDailyHoliday = summaryOT.ObjectTypeOT.xWorkingDailyHoliday || 0;
          formData.xWorkingMonthlyHoliday = summaryOT.ObjectTypeOT.xWorkingMonthlyHoliday || 0;
          formData.amountHours =(
            formData.xOT +
            formData.xOTHoliday +
            formData.xWorkingDaily +
            formData.xWorkingDailyHoliday +
            formData.xWorkingMonthlyHoliday
          ) || 0;
        }

        if (formData.idRequestType === 2) {
          formData.isDoubleApproval = formData.xOTHoliday > 0 ? 1 : 0;
          formData.approvalLevel = formData.xOTHoliday > 0 ? 2 : 1;
        }
        
        let result;
        if (values.idRequestTime) {
          result = await dispatch(updateRequestTime(formData));
        } else {
          result = await dispatch(addRequestTime(formData));
        }
        if (result) {
          if (result.errorCode) {
            handleChangeAlertType(
              formData.idRequestType === 1
                ? "ขอรับรองเวลาทำงานซ้ำ"
                : "ขอทำงานล่วงเวลาซ้ำ"
            );
            handleOpenAlert(true);
          } else {
            handleChangeAlertType("success");
            handleOpenAlert(true);
            if (isUserTimelinePage) {
              await dispatch(getAttendanceById(searchDate));
            } else {
              await dispatch(
                getAttendanceCompanyByIdEmp(
                  searchDate.searchDate,
                  searchDate.idCompany,
                  searchDate.idEmp
                )
              );
            }
          }
          handleCloseDialog();
        } else {
          handleOpenAlert(true);
          handleChangeAlertType("error");
          handleCloseDialog();
        }
      }
    }
  };

  const renderHoursOT = () => {
    if (summaryOT.isErrorBreakTime) {
      return (
        <Typography
          variant="body2"
          style={{ color: "#f15e5e", fontWeight: 500 }}
        >
          เวลาทำงานเร่งด่วนช่วงพักเที่ยง ไม่ถูกต้อง
        </Typography>
      );
    } else if (summaryOT.isOutOfRange) {
      return (
        <Typography
          variant="body2"
          style={{ color: "#f15e5e", fontWeight: 500 }}
        >
          วันที่หรือเวลาไม่ถูกต้อง
        </Typography>
      );
    } else if (summaryOT.overlapWorking > 0) {
      return (
        <Typography
          variant="body2"
          style={{ color: "#f15e5e", fontWeight: 500 }}
        >
          ไม่สามารถขอทำงานล่วงเวลาในเวลาทำงานปกติได้
        </Typography>
      );
    } else if (!summaryOT.isInTimeFrame){
      return (
        <Typography
          variant="body2"
          style={{ color: "#f15e5e", fontWeight: 500 }}
        >
          ไม่สามารถขอทำงานล่วงเวลาเกินกรอบเวลาที่ท่านได้บันทึกเวลาทำงานไว้ได้
        </Typography>
      );
    }else {
      let ObjectOt = summaryOT.ObjectOt;
      return (
        <>
          {Object.keys(ObjectOt).map((k, index) => {
            return (
              <StyledWrapHoursOT key={`${k}_${ObjectOt[k]}_${index}`}>
                <Typography
                  className="label"
                  variant="subtitle1"
                  color="text.third"
                >
                  OT x {k}
                </Typography>
                <Typography variant="h6">
                  {parseFloat(ObjectOt[k]).toFixed(2)} ชม.
                </Typography>
              </StyledWrapHoursOT>
            );
          })}
        </>
      );
    }
  };

  const renderTitle = () => {
    let text = "";
    if (values.idRequestTime) {
      if (values.idRequestType === 2) {
        text = t("OtRequest");
      } else {
        text = t("requestWorkingTime");
      }
    } else {
      text = t("AddTimeline");
    }

    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>{text}</Typography>
        {values.idRequestTime && (
          <DeleteForeverIcon
            style={{
              color: "d32f2f",
              cursor: "pointer",
            }}
            onClick={handleOpenDialogConfirmDelete}
          />
        )}
      </div>
    );
  };

  const renderManagerLV1 = () => {
    return (
      <Fragment>
        <Grid item xs={12} sm={4} style={{ display: "flex" }}>
          <StyledContentLabel>{t("ManagerLevel")} 1</StyledContentLabel>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography>{watch("managerLV1Name")}</Typography>
          <Typography color={"text.secondary"} variant="body2">
            {watch("positionManagerLV1")}
          </Typography>
          <div className="wrap-status">
            <div style={{ width: 40 }}>
              <StatusRequest
                boxSize={30}
                fontSize={20}
                borderRadius={8}
                status={
                  watch("isManagerLV1Approve") === null
                    ? 2
                    : watch("isManagerLV1Approve")
                      ? 1
                      : 0
                }
                active={values.isActive}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 8,
              }}
            >
              <Typography>
                {watch("isManagerLV1Approve") === null
                  ? `${t("WaitingApproval")}`
                  : watch("isManagerLV1Approve")
                    ? `${t("Approve")}`
                    : `${t("Disapproval")}`}
              </Typography>
              {watch("managerLV1ApproveDate") && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  style={{
                    fontStyle: "italic",
                  }}
                >{`${watch("managerLV1ApproveDateText")}`}</Typography>
              )}
            </div>
          </div>
        </Grid>
      </Fragment>
    );
  };

  const renderManagerLV2 = () => {
    return (
      <Fragment>
        <Grid item xs={12} sm={4} style={{ display: "flex" }}>
          <StyledContentLabel>{t("ManagerLevel")} 2</StyledContentLabel>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography>{watch("managerLV2Name")}</Typography>
          <Typography color={"text.secondary"} variant="body2">
            {watch("positionManagerLV2")}
          </Typography>
          <div className="wrap-status">
            <div style={{ width: 40 }}>
              <StatusRequest
                boxSize={30}
                fontSize={20}
                borderRadius={8}
                status={
                  watch("isManagerLV2Approve") === null
                    ? 2
                    : watch("isManagerLV2Approve")
                      ? 1
                      : 0
                }
                active={values.isActive}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 8,
              }}
            >
              <Typography>
                {watch("isManagerLV2Approve") === null
                  ? `${t("WaitingApproval")}`
                  : 
                    watch("isManagerLV2Approve")
                  ? `${t("Approve")}`
                  : `${t("Disapproval")}`}
              </Typography>
              {watch("managerLV2ApproveDateText") && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  style={{
                    fontStyle: "italic",
                  }}
                >{`${watch("managerLV2ApproveDateText")}`}</Typography>
              )}
            </div>
          </div>
        </Grid>
      </Fragment>
    );
  };

  const disabledBtn = () => {
    if(values &&
      watch("idRequestType") &&
      watch("startDate") && !isNaN(parseInt(watch("startHours"))) && !isNaN(parseInt(watch("startMinute"))) &&
      watch("endDate") && !isNaN(parseInt(watch("endHours"))) && !isNaN(parseInt(watch("endMinute"))) &&
      watch("idRequestReason")
    ){
      const startDate = dayjs(watch("startDate"))
        .set("hour", watch("startHours"))
        .set("minute", watch("startMinute"))
        .set("second", 0);
      const endDate = dayjs(watch("endDate"))
        .set("hour", watch("endHours"))
        .set("minute", watch("endMinute"))
        .set("second", 0);

      let allHours = endDate.diff(startDate, "hours", true);

      if(allHours > 0){
        if(watch("idRequestType") === 2){
          if(!summaryOT || summaryOT.isErrorBreakTime || summaryOT.isOutOfRange || summaryOT.overlapWorking > 0 || !summaryOT.isInTimeFrame){
            return true;
          }else{
            return false;
          }
        }else{
          return false;
        }
      }else{
        return true;
      }
    }else{
      return true;
    }
  };

  return (
    <DrawerCustom
      title={renderTitle()}
      anchor="right"
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        <div style={{ marginBottom: 16 }}>
          <Typography>
            {t("WorkingDay")}: {dayjs(values.date).format("D/MM/BBBB ")}
          </Typography>
          <Typography>
            {t("WorkingTime")}:{" "}
            {values.pattern.idWorkingType === 1
              ? `${values.pattern.nameShiftGroup}  (${values.pattern.nameShift})`
              : `${values.pattern.nameShiftGroup}`}
            <Typography
              component="span"
              color="text.third"
              style={{
                fontStyle: "oblique",
                fontWeight: "normal",
              }}
            >
              {values.pattern.isWorkingDay === 1
                ? ` (${values.pattern.timeIn.slice(
                    0,
                    -3
                  )} - ${values.pattern.timeOut.slice(0, -3)})`
                : ` (${values.pattern.nameShiftType})`}
            </Typography>
          </Typography>
        </div>
        <Grid container spacing={2}>
          {watch("idRequestTime") && (
            <Fragment>
              <Grid
                item
                xs={12}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  color={"text.third"}
                  style={{ fontSize: 14, fontWeight: 500 }}
                >
                  {t("Approver")}
                </Typography>
              </Grid>
              {watch("isDoubleApproval") ? (
                <Fragment>
                  {renderManagerLV1()}
                  {renderManagerLV2()}
                </Fragment>
              ) : watch("approvalLevel") ? (
                renderManagerLV1()
              ) : (
                renderManagerLV2()
              )}
            </Fragment>
          )}
          {watch("idRequestTime") && (
            <Fragment>
              <Grid
                item
                xs={12}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  color={"text.third"}
                  style={{ fontSize: 14, fontWeight: 500 }}
                >
                  {t("Description")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  color={"text.secondary"}
                  variant="body2"
                  style={{
                    fontStyle: "oblique",
                    textAlign: "right",
                    width: "100%",
                  }}
                >
                  {`${t("CreateDate")}: ${watch("createDateText")}`}
                </Typography>
              </Grid>
            </Fragment>
          )}
          <Grid
            item
            xs={12}
            sm={4}
            style={{ display: "flex", alignItems: "center" }}
          >
            <StyledContentLabel>{t("RequestType")}</StyledContentLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            <StyledFormControl fullWidth>
              <Controller 
                name="idRequestType"
                control={control}
                render={({ field }) => (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    inputProps={{ readOnly: mode === "read" }}
                  >
                    <MenuItem value={""} disabled style={{ padding:"0" }}></MenuItem>
                    <MenuItem value={1}>{t("WorkTimeRequest")}</MenuItem>
                    <MenuItem value={2}>{t("OvertimeRequest")}</MenuItem>
                  </Select>
                )}
              />
              
            </StyledFormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={{ display: "flex", alignItems: "center" }}
          >
            <StyledContentLabel>{t("StartDate")}</StyledContentLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Controller 
              name="startDate"
              control={control}
              render={({ field }) => {
                if(localStorage.getItem("language") === "en"){
                  return (
                    <DatePickerEng
                      minDate={new Date(dayjs(values.date).format("YYYY-MM-DD"))}
                      maxDate={
                        new Date(
                          dayjs(values.date).add(1, "day").format("YYYY-MM-DD")
                        )
                      }
                      readOnly={
                        parseInt(watch("idRequestType")) === 1 || mode === "read"
                      }
                      {...field}
                      onChange={(newDate) => {
                        field.onChange(newDate);
                      }}
                      variant="outlined"
                    />
                  );
                }else{
                  return (
                    <DatePickerThai
                      minDate={new Date(dayjs(values.date).format("YYYY-MM-DD"))}
                      maxDate={
                        new Date(
                          dayjs(values.date).add(1, "day").format("YYYY-MM-DD")
                        )
                      }
                      readOnly={
                        watch("idRequestType") === 1 || mode === "read"
                      }
                      {...field}
                      onChange={(newDate) => {
                        field.onChange(newDate);
                      }}
                      variant="outlined"
                    />
                  );
                }
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={{ display: "flex", alignItems: "center" }}
          >
            <StyledContentLabel>{t("StartTime")}</StyledContentLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%", marginRight: 4 }}>
                <Controller 
                  name="startHours"
                  control={control}
                  render={({ field }) => (
                    <TimePicker
                      handleChangeTime={handleChangeTime}
                      name={field.name}
                      unit="hour"
                      value={field.value}
                      mode={mode}
                    />
                  )}
                />
              </div>
              <div style={{ width: "100%", marginLeft: 4 }}>
                <Controller 
                  name="startMinute"
                  control={control}
                  render={({ field }) => (
                    <TimePicker
                      handleChangeTime={handleChangeTime}
                      name={field.name}
                      unit="minute"
                      step="1"
                      value={field.value}
                      mode={mode}
                    />
                  )}
                />
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={{ display: "flex", alignItems: "center" }}
          >
            <StyledContentLabel>{t("EndDate")}</StyledContentLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Controller 
              name="endDate"
              control={control}
              render={({ field }) => {
                if(localStorage.getItem("language") === "en"){
                  return (
                    <DatePickerEng
                      minDate={new Date(dayjs(values.date).format("YYYY-MM-DD"))}
                      maxDate={
                        new Date(
                          dayjs(values.date).add(1, "day").format("YYYY-MM-DD")
                        )
                      }
                      readOnly={mode === "read"}
                      {...field}
                      onChange={(newDate) => {
                        field.onChange(newDate);
                      }}
                      variant="outlined"
                    />
                  );
                }else{
                  return (
                    <DatePickerThai
                      minDate={new Date(dayjs(values.date).format("YYYY-MM-DD"))}
                      maxDate={
                        new Date(
                          dayjs(values.date).add(1, "day").format("YYYY-MM-DD")
                        )
                      }
                      readOnly={mode === "read"}
                      {...field}
                      onChange={(newDate) => {
                        field.onChange(newDate);
                      }}
                      variant="outlined"
                    />
                  );
                }
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={{ display: "flex", alignItems: "center" }}
          >
            <StyledContentLabel>{t("EndTime")}</StyledContentLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%", marginRight: 4 }}>
                <Controller 
                  control={control}
                  name="endHours"
                  render={({ field }) => (
                    <TimePicker
                      handleChangeTime={handleChangeTime}
                      name={field.name}
                      unit="hour"
                      value={field.value}
                      mode={mode}
                    />
                  )}
                />
              </div>
              <div style={{ width: "100%", marginLeft: 4 }}>
                <Controller 
                  control={control}
                  name="endMinute"
                  render={({ field }) => (
                    <TimePicker
                      handleChangeTime={handleChangeTime}
                      name={field.name}
                      unit="minute"
                      step="1"
                      value={field.value}
                      mode={mode}
                    />
                  )}
                />
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={{ display: "flex", alignItems: "center" }}
          >
            <StyledContentLabel>{t("Remark")}</StyledContentLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            <StyledFormControl fullWidth>
              <Controller 
                control={control}
                name="idRequestReason"
                render={({ field }) => (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="idRequestReason"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    disabled={!Boolean(watch("idRequestType"))}
                    inputProps={{ readOnly: mode === "read" }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    }}
                  >
                    {
                      [
                        requestTimeReasonList &&
                          requestTimeReasonList
                            .filter((x) => {
                              if (watch("idRequestType") === 1) {
                                return (x.idRequestType === 1 || x.idRequestType === null) && x.isResign != 1;
                              } else if (watch("idRequestType") === 2) {
                                return (x.idRequestType === 2 || x.idRequestType === null) && x.isResign != 1;
                              }
                            })
                            .map((item, index) => (
                              <MenuItem key={index} value={item.idRequestReason}>
                                {item.name}
                              </MenuItem>
                            )),
                      ]
                    }
                  </Select>
                )}
              />
            </StyledFormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={{ display: "flex", alignItems: "center" }}
          >
            <StyledContentLabel>{t("AdditionalReasons")}</StyledContentLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            <StyledFormControl fullWidth>
              <Controller 
                control={control}
                name="otherReason"
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="outlined-multiline-static"
                    multiline
                    rows={3}
                    InputProps={{
                      readOnly: mode === "read",
                    }}
                  />
                )}
              />
            </StyledFormControl>
          </Grid>
          {watch("idRequestType") === 2 && (
            <Fragment>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <StyledContentLabel>{t("TotalHours")}</StyledContentLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                {summaryOT && renderHoursOT()}
              </Grid>
            </Fragment>
          )}
        </Grid>
        <StyledFooter>
          <ButtonBlue
            className="cancel"
            color="secondary"
            variant="text"
            onClick={handleCloseDialog}
          >
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue
            disabled={disabledBtn() || isSubmit}
            variant="contained"
            onClick={handleSaveRequestTime}
          >
            {t("Save")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogNewRequest;
